@import '../../colors.scss';

.datePickerContainer {
  display: flex;
  flex-direction: column;

  .datePickerField {
    border: 1px solid $secondaryBorder;
    border-radius: 4px;
    color: $primaryText;
    margin-top: 8px;
    padding: 3px 5px;
    outline: none;

    >div {
      border: 0;
    }

    button:focus {
      outline: none;
    }
  }

  input,
  span,
  button {
    color: $normalText;
    outline: none;
  }

  .datePickerWrapper,
  .datePickerAlcoholContainer {
    border: 1px solid $secondaryBorder !important;
    border-radius: 4px !important;
    padding: 4px;
    width: 100%;
    margin-top: 8px;
    height: 36px;
  }

  .datePickerAlcoholContainer {
    height: 32px;
  }
}