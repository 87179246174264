@import '../../colors.scss';

input[type='range'] {
  -webkit-appearance: none;
  -moz-apperance: none;
  margin: 30px 0;
  width: 100%;
  height: 14px;
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(0, #f49c00),
      color-stop(0, #f7f7f7));
  border-radius: 20px;
}

input[type='range']:focus {
  outline: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  background-image: url('../../assets/pin.svg');
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  /* Resize the background image to cover the entire container */
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 0px solid #000000;
  cursor: pointer;
  margin-top: -30px;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 10px;
  z-index: 2;
  color: #13bba4;
  margin-top: -1px;
}

/** FF*/
input[type='range']::-moz-range-progress {
  background-color: #f49c00;
}

input[type='range']::-moz-range-track {
  background-color: #f7f7f7;
}

/* IE*/
input[type='range']::-ms-fill-lower {
  background-color: #f49c00;
}

input[type='range']::-ms-fill-upper {
  background-color: #f7f7f7;
}

.datalist {
  display: flex;
  flex-direction: row;
  margin-top: -30px;
  padding-left: 10px;
  padding-right: 5px;
  padding-bottom: 30px;
  align-items: center;
  justify-content: space-between;
}

.datalist .option::before {
  content: '';
  color: #000;
  position: absolute;
  top: 50%;
  border-left: 1px solid #000;
  left: 50%;
  height: 12px;
  transform: rotate(180deg);
  margin-top: -22px;
}

.datalist .optionShort::before {
  content: '';
  color: #000;
  position: absolute;
  // top: 50%;
  border-left: 1px solid #000;
  // left: 50%;
  height: 10px;
  transform: rotate(180deg);
  margin-top: -22px;
}

input[type='range']+.datalist .option {
  display: inline-block;
  width: calc((100% - var(--thumb-width)) / (var(--list-length) - 1));
  text-align: center;
  position: relative;
}