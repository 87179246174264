@import '../../colors.scss';

.card {
	margin-top: 20px;
	padding: 20px;
}

.cardBorder {
	border: 1px solid $secondaryBorder;
}

.cardBackground {
	background-color: $normalColor;
}