@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.pageContainerHeader {
  padding: 0px 15px 20px 10px;
  margin-bottom: 10px;
  border-bottom: solid 2px $primaryBorder;
}

.uploadCard {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.helpCircle {
  color: $secondaryText;
  margin-left: 10px;
  margin-top: 50px;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
}

.Modal {
  margin: 80px auto !important;
  max-width: 500px;
}

.btnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  height: 35px;
  width: 35px;
  color: $normalText;
  border-radius: 4px;
  font-size: 18px;
}

.buttonLink {
  color: rgba(0, 90, 158, 1);
}

.modelHelpCircle {
  color: $secondaryText;
  margin-left: 10px;
  margin-top: 0px;
}

.disabledField {
  background: #fff;
  border: none !important;
  cursor: default !important;
}

.applicant {
  flex: 1;
  position: relative;

  .editIcon {
    position: absolute;
    right: 18px;
    bottom: 6px;
    cursor: pointer;
  }
}

.modalContainer {
  width: 380px;
  min-height: 200px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;

  .modalButton {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}