@import '../../colors.scss';

.beerImage {
	width: 32px;
	height: 32px;	
	background-color: '#FFFFF';
	border-radius: 5px;	
	background-position: center;
  	background-repeat: no-repeat; 
	background-size: cover; 		  
}

.innerCard {
	display: flex;
	flex-direction: column;
	width: 100%;	
	flex-shrink: 0;
	margin-top: 4px;
	background-color: #FFF;
	padding: 3px;
	border-radius: 5px;
	align-self: 'stretch';
	cursor: pointer;
	border: 1px solid #dadada
}

.innerCard > label {
	cursor: pointer;
}

