@import './../../colors.scss';

.top {
  top: 5px; 
}

.bottom {
  bottom: 5px;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.alert p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.alert {
  padding: 0.01em 16px;
  border-radius: 8px;
  position: fixed;
  right:2%;
  width: 20%;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
}

.alert-entering { 
  opacity: 0
}
.alert-entered { 
  opacity: 1
}
.alert-exiting { 
  opacity: 0
}
.alert-exited { 
  opacity: 0
}

.info {
  color: $infoColor;
  background-color: $infoBackgroundColor;
}

.error {
  color: $errorColor;
  background-color: $errorBackgroundColor;
}

.success {
  color: $successColor;
  background-color: $successBackgroundColor;
}

.warning {
  color: $warningColor;
  background-color: $warningBackgroundColor;
}
