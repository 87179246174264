@import '../../../colors.scss';

.buttonShow {
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 12px;
}

.containerComments {
  width: 100%;
}

.container {
  flex-flow: column !important;
  overflow: auto;
  background-color: #fff;
}

.performanceContainer {
  flex-flow: column !important;
  overflow-y: scroll;
  overflow-x: auto;
  background-color: #fff;
  height: 350px;
}

.roundInfo {
  display: flex;
  padding: 15px 0 !important;
  margin: 0 15px !important;
}

.roundInfoLeftSide {
  min-width: 250px;
}

.scoreText {
  color: #f49b00;
}

.containerSample {
  flex-flow: row !important;
  overflow: auto;
  background-color: #fff;
}

.cardColumn {
  position: relative;
  overflow: hidden;
  width: 300px;
  flex: 1 1 300px !important;
  max-width: 300px;
  flex-basis: 300px;
  min-width: 300px;
  flex-grow: 1;
  text-align: center;
}

.textComments {
  background-color: #f49b00;
  color: #fff;
  border-radius: 16px;
  padding: 5px !important;
  margin: 2px !important;
  width: 100%;
  word-wrap: break-word;
}

.textSamples {
  border-radius: 16px;
  padding: 5px !important;
  margin: 2px !important;
}

.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.detailImg {
  height: 12px;
  margin: 8px 0 12px;
}