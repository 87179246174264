@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
  min-height: calc(100vh - 80px);
  position: relative;
}

.container {
  background-color: #efefef;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}

.filter {
  flex: 1;
  padding: 4px;
}

.filterTitle {
  font-size: 10px;
}

.filterInput {
  margin-top: 4px;
  border-radius: 50px;
}

.filterWrapper {
  background-color: #fff;
  height: 55px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-color: #efefef;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dateInput {
  max-width: 50px;
  border-radius: 50px;
  background-color: #efefef;
  padding: 5px;
  height: 27px;
  flex: 1;
  border-color: #fff;
}

.picker {
  // inputIOS {
  //   fontSize: 12,
  //   borderRadius: 50,
  //   backgroundColor: '#efefef',
  //   borderColor: '#efefef',
  //   padding: 5,
  //   paddingLeft: 8,
  //   height: 25
  // },
  // inputAndroid: {
  //   fontSize: 12,
  //   borderRadius: 50,
  //   backgroundColor: '#efefef',
  //   borderColor: '#efefef',
  //   padding: 5,
  //   paddingLeft: 8,
  //   height: 25
  // },

  border-radius: 50px;
  background-color: #efefef;
  border-color: #efefef;
  padding: 5px;
  height: 25px;
}

.textInput {
  border-radius: 50px;
  background-color: #efefef;
  padding: 5px;
  height: 25px;
}

.roundPickerBorders {
  border-radius: 50px;
  overflow: hidden;
  height: 25px;
  margin-top: 4px;
}

.dateClear {
  position: absolute;
  right: 15px;
  color: #999;
  top: 28px;
}

.iconPicker {
  margin-top: 6px;
  margin-right: 6px;
}

.showListModal {
  display: block;
  position: absolute;
  top: 0;
  z-index: 10;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  background: rgba(0, 0, 0, 0.15);
  overflow: auto;

  .showListBox {
    width: 80%;
    margin: auto !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
    padding: 0 5px !important;
    z-index: 10 !important;
    background: #fff;
    position: absolute;
    top: calc((100vh - 100px) / 2);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: calc(100vh - 100px);
    overflow-y: auto;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.closeButton {
  display: flex;
  justify-content: flex-end;
}

.title {
  margin: 10px auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}