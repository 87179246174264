@import '../../colors.scss';

%default-button-style {
  border: 0;
  padding: 10px 0;
  border-radius: 60px;
  outline: none;
  cursor: pointer;
  font-size: 0.9em;
}

.uploadButton {
  @extend %default-button-style;
  background-color: $secondaryColor;
  color: $normalColor;
  white-space: nowrap;
  padding: 10px 5px 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.disabledButton {
  @extend %default-button-style;
  background-color: #ccc;
  color: #eee;
  cursor: not-allowed;
  white-space: nowrap;
  padding: 10px 5px 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.uploadButton > svg {
  margin-left: 5px;
  margin-right: 10px;
}

.uploadInput {
  display: none;
}
