@import '../../colors.scss';

.comment {
  width: 100%;
  padding: 10px;
}

.boxComments {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 100%;
  margin: auto;
  resize: none;
  height: 75px;
  outline: none;
}

.boxComments:focus {
  border-color: $secondaryColor;
}

.textData {
  cursor: pointer;
}

.sampleColumn {
  width: 14%;
  max-width: 14%;
}

.textInColumn {
  max-width: 140px;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
  word-break: break-all;
}

.sampleCard {
  background-color: $primaryGrey;
  width: 225px;
  width: 300px;
}

.card {
  background-color: $primaryGrey;
  margin: 10px !important;
  padding: 5px;
  border-radius: 6px;
}

.taster {
  background-color: $primaryGrey;
  margin: 10px;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 210px;
  height: 65px;
}

.greyBackground {
  background-color: $primaryGrey;
  margin: 10px;
  border-radius: 6px;
}

.sampleCardRow {
  margin: 5px;
  padding: 0px;
  flex-wrap: wrap;
  width: calc(100% - 10px);
}

.scoreBox {
  align-items: center;
}

.buttonProfile {
  border-radius: 5px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 100%;
}

.buttonBackGround {
  width: 100%;
  height: 100%;
}

.showFull {
  width: 100%;
  height: 100%;
}

.orderBox {
  font-weight: bold;
  font-size: 14px;

  .beerIconBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: -12px 8px 0 0;

    .beerIcon {
      margin: 10px 0 0 -8px;
      width: 32px;
    }

    .orderText {
      margin-top: -24px;
    }
  }

  .title {
    overflow-y: auto;
    height: 40px;
    display: flex;
    align-items: center;
    line-height: 20px;
  }
}

.shadeGray {
  width: 46px;
  height: 18px;
  margin-top: 4px;
}