@import '../../../colors.scss';

.maxTop {
  background-color: transparent;
}

.cardLabelTitle {
  margin-top: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .topLabel {
    color: #f49b00;
    font-size: 1.2em;
    font-weight: bold;
  }
}

.listContainer {
  display: flex;
  flex-direction: column !important;

  .triangleTitle {
    height: 32px;
    font-size: 24px;
    font-weight: 400;
    color: #656872;
    line-height: 32px;
    margin-left: 5px;
    width: 90%;

    .triangleTitleSpan1 {
      color: #656872;
      width: 90%;
    }

    .triangleTitleSpan {
      // width: 900px;
      overflow-y: auto;
      word-wrap: break-word;
      font-weight: bold;
      color: #323232;
    }
  }

  .sampleContainer {
    margin-top: 55px;
    display: flex;
    flex-wrap: wrap;

    .cardTitle {
      width: 66px;
      height: 20px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.64);
      line-height: 20px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .cardTitleContent {
      width: 455px;
      height: 70px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e2e8f0;
      margin-bottom: 20px;
      padding: 5px 10px 5px 10px;
      word-wrap: break-word;
      overflow-y: auto;
      line-height: 20px;
    }
  }

  .setContainer {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}

.finishButton {
  background-color: #f49b00;
  border-radius: 5px;
  flex: 1;
  margin-left: 5px;
  color: white;
  font-weight: 500;
  align-self: center;
}

.commentButton {
  background-color: #f49b00;
  border-radius: 5px;
  flex: 1;
  margin-left: 5px;
  // margin-top: 20px;
  font-weight: 500;
  align-self: center;
  width: 155px;
  height: 40px;
}

.nextButton {
  position: absolute;
  right: 0;
  background-color: #f49b00;
  border-radius: 5px;
  flex: 1;
  color: white;
  font-weight: 500;
  align-self: center;
  margin-left: 30px;
}

.closeBtnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;

  .closeButton {
    position: absolute;
    right: 0;
    background-color: #f49b00;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    align-self: center;
    margin-left: 30px;
  }
}

.setCard,
.selectedCard {
  position: relative;
  width: 300px;
  height: 330px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  margin: 0 20px 20px 10px;
  flex: none;

  .optionSet {
    margin: 15px auto 0px;
    margin-bottom: 20px;
    font-weight: 500;
    width: 84px;
    height: 38px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 18px;

    .imgWrong,
    .imgRight {
      width: 30px;
      height: 30px;
      color: #0ba248;
      font-weight: bolder;
    }

    .imgWrong {
      color: #eb3b46;
    }
  }

  .optionContainer {
    width: 65%;
    height: 80px;
    background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
    background: #f1f1f1 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .optionSelect {
    margin: 0 auto;
    margin-top: 5px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .answer {
    margin: 10px 0 3px 10px;
    height: 16px;
    font-size: 14px;
    color: #666666;
    margin-left: 10px;
    padding-left: 5px;
  }

  .answerContainer {
    width: 87%;
    height: 34%;
    background: #ffffff;
    border: 0.7px solid #cfcfd1;
    position: absolute;
    left: 50%;
    margin-top: 10px;
    transform: translateX(-50%);
  }

  .commentList {
    overflow: auto;
    padding: 0px 0px 0px 5px;

    .showComments,
    .noComment {
      min-height: 22px;
      border: 0;
      padding: 0 8px;
      display: block;
      border-radius: 25px;
      margin: 0 0 5px 0;
      color: #fff;
      background-color: rgb(244, 156, 45);
      width: 100%;
    }

    .noComment {
      background-color: rgb(149, 197, 17);
    }
  }

  .commentList {
    height: 100%;
    width: 100%;
    padding: 5px 10px 0 10px;
    overflow: auto;
  }

  .Button,
  .Button1 {
    position: relative;
    margin-left: 240px;
    margin-top: 30px;
    width: 155px;
    height: 40px;
    background: #d1a33c;
  }

  .evaluate {
    font-size: 14px;
    width: 90%;
    height: 30px;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.selectedCard {
  border-color: #f6b45e;
  border: 3px solid #f6b45e;
}

.title,
.code {
  font-size: 24px;
  color: #bdc4c7;
  font-weight: 600;
}

.code {
  font-size: 20px;
}

.int_round {
  background: #f7f7f7 !important;
  display: flex;
  justify-content: center;
  margin: 30px 0 26px 0 !important;
  color: #f6b45e;
  font-size: 20px;
  font-weight: 600;
}

.card {
  background: #f7f7f7 !important;
  padding: 0 16px !important;
  justify-content: center;
  flex-wrap: wrap;
}

.img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
}

.tasting {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.updown {
  list-style: none;
}

.updown:hover {
  cursor: pointer;
}

.Button,
.Button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
}

.Button1 {
  margin: 15px 0 0 0;
}

.p1 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.p2 {
  font-size: 18px;
}

.commentList {
  height: 112px;
  width: 100%;
  padding: 5px 10px 0 10px;
  overflow: auto;
  list-style: none;
}

.triangle {
  border: 5px solid #fff;
  border-top: 5px solid rgb(244, 156, 45);
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.header {
  display: flex;

  .back {
    border-radius: 40px;
    background-color: #f49b00;
    color: #fff;
    font-weight: bold;
    width: 40px;
    height: 40px;
    padding: 4px 0px 0px 0px;
  }

  .upAndDown {
    margin-top: 5px;
  }
}

.countDownDiv {
  position: absolute;
  right: -8px;
  top: 0px;
}

.countDownColumn {
  position: relative;
}

.alertRow {
  justify-content: center;
  padding-top: 10px;
}

.alert {
  color: #f49b00;
  font-size: 20px;
  margin: -2px 2px;
}

.alertText {
  font-size: 18px;
  color: #000;
}

.minScreen {
  display: none !important;
}


@media screen and (max-width: 1000px) and (max-height: 1000px) {
  .maxScreen {
    display: none !important;
  }

  .minScreen {
    display: flex !important;

    .NotFinishButton,
    .finishButton {
      font-size: 12px;
      width: auto;
      height: auto;
      padding: 8px
    }
  }

  .setContainer {
    flex-wrap: wrap;
    justify-content: space-around;


  }

  .sampleContainer {
    flex-direction: column;
  }

  .cardTitle {
    margin: 0px !important;
  }

  .cardMin {
    margin: 0px !important;
    padding: 8px !important;
    width: 100% !important;
  }

  .sampleContainer {
    margin-top: 6px !important;
    // width: 90% !important;
    display: block !important;
  }

  .triangleTitle {
    font-size: 16px !important;
    line-height: 20px !important;

    .triangleTitleSpan {
      width: auto;
    }
  }
}


@media screen and (max-width: 1000px) and (min-width: 501px) {

  .selectedCard,
  .setCard {
    width: 30vw;
    margin: 6px !important;
  }
}

@media screen and (max-width: 500px) {
  .listContainer {
    display: flex;
    flex-direction: column !important;
  }

  .selectedCard,
  .setCard {
    width: 42vw;
    margin: 6px !important;
  }
}