@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.container { 
  background-color: '#efefef';
  flex: 1;
  flex-direction: 'row';
  flex-wrap: 'wrap';
}

.filter {
  flex: 1;
  padding: 4;
}

.filterTitle {
  font-size: 10px;
}

.filterInput {
  margin-top: 4px;
  border-radius: 50px;
}

.filterWrapper {
  background-color: '#FFF';
  height: 55px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px; 
  border-color: '#efefef';
  flex-direction: row; 
  justify-content: 'space-between'; 
  flex-wrap: 'wrap';
}

.dateInput {
  max-width: 50px;
  border-radius: 50px;
  background-color: '#efefef';
  padding: 5px; 
  height: 27px;
  flex: 1;
  border-color: '#FFF';
}

.picker {
  // inputIOS { 
  //   fontSize: 12, 
  //   borderRadius: 50, 
  //   backgroundColor: '#efefef', 
  //   borderColor: '#efefef', 
  //   padding: 5, 
  //   paddingLeft: 8, 
  //   height: 25 
  // }, 
  // inputAndroid: { 
  //   fontSize: 12, 
  //   borderRadius: 50, 
  //   backgroundColor: '#efefef', 
  //   borderColor: '#efefef', 
  //   padding: 5, 
  //   paddingLeft: 8, 
  //   height: 25
  // },

  border-radius: 50px;
  background-color: '#efefef';
  border-color: '#efefef'; 
  padding: 5px; 
  height: 25px; 
}

.textInput {
  border-radius: 50px;
  background-color: '#efefef'; 
  padding: 5;
  height: 25px;
}

.roundPickerBorders { 
  border-radius: 50px; 
  overflow: 'hidden'; 
  height: 25px;
  margin-top: 4px;
}

.dateClear {
  position: 'absolute';
  right: 15px; 
  color: '#999'; 
  top: 28px;
}

.iconPicker {
  margin-top: 6px; 
  margin-right: 6px;
}


.header{
  padding: 0 5px !important;
  align-items: center;
  display: flex;
  h3{
      color: $secondaryColor;
  }
  justify-content: flex-start;
}

.back {
  border-radius: 40px;
  background-color: #F49B00;
  color: #fff;
  font-weight: bold;
  width: 40px;
  height: 40px;
  padding: 4px 0px 0px 0px;
}