$normalText: #666666;
$primaryText: #FFFFFF;
$secondaryText: #B11F24;
$thirdText: #F49B00;

$normalColor: #FFFFFF;
$primaryColor: #B11F24;
$secondaryColor: #F49B00;

$primaryBorder: #F7F7F7;
$secondaryBorder: #DADADA;

$primaryGrey: #ebebeb;

$infoColor: #fff;
$infoBackgroundColor: #2196F3;

$errorColor: #fff;
$errorBackgroundColor: #f44336;

$successColor: #fff;
$successBackgroundColor: #4CAF50;

$warningColor: #fff;
$warningBackgroundColor: #ff9800;
