@import '../../colors.scss';

%default-label-style {
	font-size: 1em;
}

.boldLabel {
	@extend %default-label-style;
	font-weight: 500;
}

.neutralLabel {
	@extend %default-label-style;
	color: $normalText;
}