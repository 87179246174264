@import '../../../colors.scss';

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #717171;
}

.container {
  flex-flow: column !important;
  overflow-y: scroll !important;
  height: 100%;
  max-height: calc(100vh - 130px);
}

.containerFixed {
  flex-flow: column !important;
  overflow: hidden;
  overflow-y: scroll !important;
}

.cardInfo {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  width: 225px;
  flex: 1 1 250px !important;
  max-width: 225px;
  flex-basis: 225px;
  min-width: 225px;
  flex-grow: 1;
  margin: 5px !important;
}

.filterBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tasters {
    margin: 5px;
    font-weight: bold;
  }
}

.filter {
  float: left;
  width: 100%;
  height: 28px;
  border: 0;
  outline: none;
}

.cardFilter {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  width: 225px;
  max-width: 225px;
  min-width: 225px;
  flex: none !important;
  height: 30px;
  margin: 5px;
  padding: 0;
  border: 2px solid #ccc;
}

.cardTaster {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  width: 175px;
  flex: 1 1 180px !important;
  max-width: 175px;
  min-width: 175px;
  flex-basis: 175px;
  flex-grow: 1;
  margin: 5px !important;
}

.cardNameTaster {
  position: relative;
  overflow: hidden;
  background-color: #292929;
  color: #fff;
  border-radius: 6px;
  width: 175px;
  flex: 1 1 180px !important;
  max-width: 175px;
  min-width: 175px;
  flex-basis: 175px;
  flex-grow: 1;
  height: 105px;
  margin: 5px !important;
  padding: 10px !important;
  align-items: center;
  display: flex;
}

.cardNameTasterScore {
  position: relative;
  overflow: hidden;
  background-color: #292929;
  color: #fff;
  border-radius: 6px;
  width: 170px;
  flex: 1 1 180px !important;
  max-width: 170px;
  min-width: 170px;
  flex-basis: 175px;
  flex-grow: 1;
  height: 30px;
  margin: 5px !important;
  padding: 0px !important;
  align-items: center;
  display: flex;
}

.cardNameTasterText {
  width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardNameTasterTextW100 {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tooltip {
  white-space: normal;
  word-break: keep-all;
  max-width: 250px;
}

.textTitle {
  font-size: 1em;
  color: $secondaryColor;
}

.textSubTitle {
  color: #000;
}

.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.title {
  padding: 0 10px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.iconBack {
  width: 18px;
}

.back {
  background-color: $secondaryColor;
  color: #fff;
  float: left;
  border-radius: 16px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
}

.textScore {
  font-weight: bold;
  text-align: center;
}

.cardSeparator {
  flex-flow: column !important;
  margin-bottom: 5px;
  z-index: 10;
}

.keyIcon {
  background-color: $secondaryColor;
  padding: 5px;
  border-radius: 16px;
  position: absolute;
  right: 10px;
}

.contentCard {
  position: relative;
  display: flex;
}

.penIcon {
  background-color: #fff;
  padding: 5px;
  border-radius: 16px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.textComments {
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
  word-wrap: break-word;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
}

.textSubTitleLimited {
  max-height: 50px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 75px;
  font-weight: 500;
}