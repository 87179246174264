@import '../../colors.scss';

.loading {
    color: $primaryColor;
    padding: 0px 5px;
    margin: 0 auto;
}

.loading svg{
	animation: rotate 1s infinite;
	animation-delay: 0s;
}

@keyframes rotate {
    100% {
        transform: rotate(-360deg);
    }
}