@import '../../colors.scss';

.autoCompleteContainer {
  position: relative;
  flex: 1;
}

.autoCompleteList {
  background-color: $normalColor;
  border: 1px solid $secondaryBorder;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
}

.autoCompleteOption {
  box-sizing: border-box;
  color: $normalText;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  border-bottom: solid 2px $primaryBorder;
}

.autoCompleteOption:hover {
  background-color: $primaryBorder;
  color: $normalText;
}

.autoCompleteContainer svg {
  color: $secondaryText;
  position: absolute;
  left: 5px;
  top: 30px;
}

.autoCompleteSelectionContainer {
  border: 1px solid $primaryBorder;
  border-radius: 4px;
  background-color: $primaryBorder;
  color: $normalText;
  margin-top: 8px;
  padding: 8px 5px;
  outline: none;
}

.autoCompleteSelectionContainer > svg {
  position: absolute;
  left: unset;
  right: 20px;
  top: 46px;
  cursor: pointer;
}

.autoCompleteOuterList {
  height: 100vh;
  width: 100vw;
  background-color: none;
  position: fixed;
  left: 0;
  top: 0;
}

.option {
  display: flex;
  flex-direction: column;

  width: 100%;

  overflow-x: hidden;
}

.optionLabel {
  display: flex;
  flex-direction: row;
}
