@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.tasterCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  border: 1px solid $primaryGrey;
  background-color: $primaryGrey;
  border-radius: 6px;
  padding: 6px 2px;
  margin: 15px 25px 0px 0px;
  width: 380px;
}

.competitionTasterCard {
  display: flex;
  align-items: center;
  border: 1px solid $primaryGrey;
  background-color: $primaryGrey;
  border-radius: 6px;
  padding: 6px;
  margin: 15px 25px 0px 0px;
  width: 120px;
  height: 50px;
}

.tasterCard>* {
  margin: 0px 0px;
}

.tasterCardText {
  display: flex;
  flex-direction: column;
  color: $normalText;
  width: 100%;
}

.keyIcon {
  background-color: $secondaryColor;
  color: $normalColor;
  padding: 5px;
  border-radius: 16px;
  margin-right: 5px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.keyIconInactive {
  background-color: $normalColor;
  color: $normalText;
  padding: 5px;
  border-radius: 16px;
  width: 26px;
  height: 26px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tasterNameLabel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  align-items: 'center';
}

.userImage {
  min-width: '50px';
  margin-right: '10px';
}