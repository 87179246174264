@import '../../colors.scss';

.selectionLabel {
	color: $primaryText;
	border: 0;
	padding: 2px 8px 4px 8px;
	margin: 2px;
    border-radius: 60px;
    outline: none;
	background-color: $secondaryColor;
	overflow: hidden;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
}

.selectionLabel svg {
	position: relative !important;
	left: 3px !important;
    top: 2px !important;
	cursor: pointer;
}