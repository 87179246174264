@import '../../../colors.scss';

.checke {
  position: relative;
  -webkit-appearance: none;
  min-width: 52px;
  height: 26px;
  line-height: 26px;
  background: #dddddd;
  border-radius: 26px;
  outline: none;
}

.checke:hover {
  cursor: pointer;
}

.checke:before {
  position: absolute;
  top: 2px;
  left: 2px;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 5px #ddd;
  transition: all 0.2s linear;
}

.checke:checked {
  background: #fa930d;
}

.checke:checked:before {
  left: 28px;
  transition: all 0.2s linear;
}

.Random {
  color: #0a74ff;
  font-weight: bold;
  font-size: 18px;
  margin-right: 10px;
}

.add {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ebebeb;
  color: #f1ca29;
  line-height: 35px;
  text-align: center;
  font-size: 60px;
  font-weight: 900;
  cursor: pointer;
}

.addCardBox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addCard {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  color: #f1ca29;
  border: 2px solid #f1ca29;
  line-height: 15px;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
}

.addCardText {
  margin-left: 10px;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.del {
  position: absolute !important;
  z-index: 1;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.num {
  font-size: 30px;
  line-height: 45px;
  font-weight: 400;
  cursor: default;
}

.Preview {
  width: 100%;
  min-width: 700px;
  height: 100%;
  min-height: 1150px;
  background: #f7f7f7;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
}

.centerPreview {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 100%;
}

.off {
  position: absolute;
  right: 5px;
  top: 0px;
  font-size: 35px;
  font-weight: bold;
  background: none;
  border: none;
  color: #000;
}

.off:hover {
  cursor: pointer;
}

.hr {
  width: 100%;
  height: 1px;
  background: #8b8b8b;
  border: 0;
  margin-top: -10px;
}

.hearder,
.hearder2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding-right: 15px;
  overflow-x: auto;
  overflow-y: hidden;
}

.hearder2 {
  padding-right: 43%;
}

.Order {
  margin-left: 15px;
  height: 80px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.img {
  width: 15px;
  height: 25%;
}

.li {
  height: 80px;
  display: flex;
  flex-shrink: 0;
  justify-content: start;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.seat {
  width: 100%;
  overflow-x: auto;
}

.code,
.codecopy {
  display: flex;
  margin: 25px 0 0 15px;
  padding-right: 30px;
  height: 130px;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
}

.codecopy {
  padding-right: 44%;
}

.code:nth-of-type(1)>li,
.codecopy:nth-of-type(1)>li {
  background-color: #deeefb;
}

.code:nth-of-type(2)>li,
.codecopy:nth-of-type(2)>li {
  background-color: #e3efcb;
}

.code:nth-of-type(3)>li,
.codecopy:nth-of-type(3)>li {
  background-color: #f8e8c7;
}

.code:nth-of-type(4)>li,
.codecopy:nth-of-type(4)>li {
  background-color: #f3d9d8;
}

.code:nth-of-type(5)>li,
.codecopy:nth-of-type(5)>li {
  background-color: #deeefb;
}

.code:nth-of-type(6)>li,
.codecopy:nth-of-type(6)>li {
  background-color: #e3efcb;
}

.code:nth-of-type(7)>li,
.codecopy:nth-of-type(7)>li {
  background-color: #f8e8c7;
}

.code:nth-of-type(8)>li,
.codecopy:nth-of-type(8)>li {
  background-color: #f3d9d8;
}

.codejiu {
  min-width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 32px;
  border-radius: 12px;
  background: url('../../../assets/preview/Bigbeer.png') no-repeat 36% center;
  background-size: 60% 70%;
  margin: 10px;
}

.code1 {
  background: rgba($color: #000000, $alpha: 0) !important;
  width: 100px;
  padding: 0;
  text-align: center;
}

.pagnum {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagnumpag {
  width: 40px;
  height: 40px;
  margin: 0 10px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
}

.pagnumpag:hover {
  cursor: pointer;
}

.active {
  background: #feaa00;
  color: #fff;
}

.lastNext {
  margin: 0 10px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  height: 40px;
  padding: 0 10px;
  min-width: 60px;
}

.flavorHeader {
  display: flex;
  flex-wrap: wrap;
}

.flavorBrand {
  flex: 3;
  height: 120px;
  padding: 15px;
}

.flavor_beer_batch {
  padding: 13px 15px 15px 15px;
}

.flavor_bol_template,
.flavor_beer_batch {
  flex: 3;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flavor_random,
.flavor_preview {
  flex: 1;
  height: 120px;
  padding: 40px 15px 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flavor_preview {
  padding: 35px 15px 15px 15px;
}

.column_optionsample {
  .imgDom {
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }

  .column_options {
    width: 100%;
    height: 34px;
    background: #ff5c00;
    border-radius: 4px !important;
    color: #fff;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 6px;
  }

  .column_options_disabled {
    width: 100%;
    height: 34px;
    border-radius: 4px !important;
    color: #fff;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0 6px;
  }
}

.menuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0 0 10px;
}

.beer {
  display: flex;
  align-items: center;
  margin: 0 2px;

  img {
    width: 16px;
  }

  b {
    margin-left: -10px;
    margin-top: 3px;
    font-size: 12px;
  }
}