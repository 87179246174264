@import '../../../colors.scss';

.pageContainer {
	flex-direction: column;
    display: flex;
    padding: 5px;
}

.tasterCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    width: 100%;
    border: 1px solid $primaryBorder;
    background-color: $primaryBorder;
    border-radius: 4px;
    padding: 6px 2px;
    margin: 10px 10px 0px 0px;
}

.tasterCard > * {
    margin: 0px 7px;
} 

.tasterCardText {
    display: flex;
    flex-direction: column;
    color: $normalText;
    flex: 2;
}

.tasterCardButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.tasterCardButtons > * {
    margin: 10px;
}