@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.modalContainer {
  width: 1000px;
  min-height: 200px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background: transparent;

  .bodyContent {
    display: flex;
    flex-direction: row;
  }

  .documentList {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 480px;
    margin-right: 20px;
    background: #fff;
    padding: 12px;
    border-radius: 4px 4px 0 0;
    flex: 1;
  }

  .historyList {
    width: 500px;
    background: #fff;
    padding: 12px;
    border-radius: 4px 4px 0 0;

    .historyLineContent {
      max-height: 260px;
      overflow-y: auto;
      margin-top: 10px;
    }

    .historyLine {
      margin-top: 10px;
    }

    .historyItemTitle {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      font-size: 13px;

      .historyItemTitleName {
        flex: 1;
      }

      .historyItemTitleTime {
        flex: 1;
        text-align: right;
      }
    }

    .historyBox {
      height: 260px;
      overflow-y: auto;
      margin-top: 10px
    }

    .historyItemContent {
      font-size: 12px;

      .historyItemContentText {
        display: flex;
        align-items: flex-start;
      }

      p {

        margin-left: 8px;
        margin-top: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .modalTitle {
    // text-align: center;
    font-size: 18px;
  }

  .modalContent {
    flex: 1;

    .UploadButton {
      width: 200px;
      margin: 20px auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .helpCircle {
      color: $secondaryText;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .CardDocument {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .modalButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tooltip {
  max-width: 250px;
  white-space: normal;
  word-break: break-all;
}