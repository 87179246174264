@import '../../../colors.scss';

.Modal {
  background-color: $normalColor;
  border-radius: 6px;
  margin: 0 auto;
  min-width: 400px;
  max-width: 500px;
  width: 100%;
  overflow: hidden;
  border: none;
  height: 80%;
}

.Modal:focus {
  outline: none;
}

.Overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.tasterItem {
  border-bottom: 2px solid $primaryBorder;
  padding: 10px;
}

.tastersContainer {
  overflow: auto;
  height: 90%;
  padding: 10px
}

.actionContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 10%;
  -webkit-box-shadow: 0px -3px 6px 2px rgba(0,0,0,0.12);
  -moz-box-shadow: 0px -3px 6px 2px rgba(0,0,0,0.12);
  box-shadow: 0px -3px 6px 2px rgba(0,0,0,0.12);
}