@import '../../../colors.scss';

.finishButton,
.NotFinishButton {
  background-color: #f49b00;
  border-radius: 5px;
  margin-left: 5px;
  font-weight: 500;
  align-self: center;
  width: 129px;
  height: 44px;
  color: white;
  font-size: 16px;
}

.cardLabelTitle {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;

  .topLabel {
    color: #f49b00;
    font-size: 1.2em;
    font-weight: 500;
  }
}

.NotFinishButton {
  color: #fff;
  background-color: #cfcfd1;
}

.commentButtonContainer {
  margin: 10px auto;
  display: flex;
  justify-content: center;
}

.commentButton {
  background-color: #f49b00;
  border-radius: 5px;
  font-weight: 500;
  width: 155px;
  height: 40px;
}

.Modal {
  width: 340px;
  height: 210px;
  display: absolute;
  top: 50%;
  transform: translateY(-70%);
  justify-content: center;
  align-items: center;

  .modalContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .line1,
  .line2,
  .line3 {
    margin-top: 17px;
    color: #666666;
    font-size: 14px;

    span {
      font-size: 15px;
      color: #323232;
      font-weight: bold;
    }
  }

  .line1 {
    font-weight: bolder;
    font-size: 20px;
    color: #323232;
  }

  .buttonContainer {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .cancelButton,
  .confirmButton,
  .disabledButton {
    background-color: #f49b00;
    border-radius: 5px;
    font-weight: 500;
    width: 100px;
    height: 40px;
    color: white;
    font-size: 14px;
  }

  .disabledButton {
    color: #fff;
    background-color: #666666;
  }

  .cancelButton {
    background-color: white;
    border: 2px solid #f49b00;
    color: #f49b00;
  }
}

.samplesCategories {
  width: 183px;
  height: 97px;
  background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 55px;

  span {
    font-weight: 700;
    font-size: 14px;
  }
}

.add {
  // border-color: rgb(244, 156, 45);
  border: 5px solid rgb(244, 156, 45);
  box-shadow: 0px 0px 5px rgb(244, 156, 45);
}

.titleNum {
  padding: 3px;
  font-size: 24px;
  font-weight: 600;
  color: #323232;
  margin-left: -5px;
  line-height: 35px;

  .order {
    padding: 3px;
    width: 800px;
    height: 80px;
    font-size: 24px;
    font-weight: 600;
    color: #323232;
    margin-left: -5px;
    line-height: 35px;
  }

  .titleSpan {
    margin-left: 15px;
    color: #323232;
    word-wrap: break-word;
  }
}

.setCard,
.selectedCard {
  width: 25%;
  width: 290px;
  height: 370px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  margin: 0 20px 20px 10px;
  flex: none;

  .optionSet {
    margin: 15px auto 5px;
    font-weight: 500;
    width: 84px;
    height: 28px;
    color: #000000;
    font-size: 18px;
  }

  .optionContainer {
    width: 65%;
    height: 80px;
    background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
    background: #f1f1f1 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .optionSelect {
    margin-top: 10px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .answer {
    height: 16px;
    font-size: 14px;
    color: #666666;
  }

  .comment {
    height: 16px;
    font-size: 13px;
    color: #000000;
    margin-left: 17px;
  }

  .answerContainer {
    padding: 4px 0px 0px 5px;
    width: 87%;
    height: 34%;
    background: #ffffff;
    border: 0.7px solid #cfcfd1;
    margin: 12px auto;
  }

  .commentList {
    padding: 5px 10px 0 10px;
    overflow: auto;
    list-style: none;

    .showComments,
    .noComment {
      border: 0;
      padding: 5px 8px;
      display: block;
      border-radius: 25px;
      margin: 0 0 5px 0;
      color: #fff;
      background-color: rgb(244, 156, 45);
      width: 100%;
    }

    .noComment {
      background-color: rgb(149, 197, 17);
    }
  }

  .ShowComments {
    min-height: 22px;
    border: 0;
    padding: 0 8px;
    display: block;
    border-radius: 25px;
    margin: 0 5px 5px 0;
    color: #fff;
  }

  .commentList {
    height: 112px;
    width: 100%;
    padding: 5px 10px 0 10px;
    overflow: auto;
  }

  .Button,
  .Button1 {
    // position: relative;
    margin: 0 auto;
    margin-top: 30px;
    // transform: translateX(50%);
    width: 155px;
    height: 40px;
    background: #d1a33c;
  }

  .evaluate {
    font-size: 14px;
    width: 90%;
    height: 30px;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.selectedCard {
  border-color: #f6b45e;
  border: 3px solid #f6b45e;
}

.title,
.code {
  font-size: 24px;
  color: #bdc4c7;
  font-weight: 600;
}

.headers {
  background: #f7f7f7 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .center {
    flex: none !important;
    display: flex;
    align-items: center;
    font-size: 19px;
    color: #666666;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }
}

.code {
  font-size: 20px;
}

.int_round {
  background: #f7f7f7 !important;
  display: flex;
  justify-content: center;
  margin: 30px 0 26px 0 !important;
  color: #f6b45e;
  font-size: 20px;
  font-weight: 600;
}

.card {
  background: #f7f7f7 !important;
  padding: 0 16px !important;
  justify-content: center;
  flex-wrap: wrap;
}

.img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
}

.tasting {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.updown {
  list-style: none;
}

.updown:hover {
  cursor: pointer;
}

.Button,
.Button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
}

.Button1 {
  margin: 15px 0 0 0;
}

.p1 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.p2 {
  font-size: 18px;
}

.hh {
  font-size: 20px;
  margin: 0 0 0 5px;
}

.hr {
  border: 0.6px solid rgba(0, 0, 0, 0.06);
}

.touxiang {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.off {
  color: #f6b45e;
  font-size: 30px;
  width: 30px;
  height: 30px;
  border: 1px solid #768198;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  width: 100%;
  height: 100%;
  z-index: 5;
}

.flaovrlist {
  width: 240px;
  display: flex;
  border-top: 2px solid #ffcdcf;
  border-bottom: 2px solid #ffcdcf;
  margin: 0 10px;
  margin-bottom: -2px;
  overflow-x: auto;
  align-items: center;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.dui {
  color: #96c80d;
  font-size: 40px;
  text-align: center;
  margin: 5px 0;
}

.sanjiao {
  width: 22px;
  height: 22px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  transform: rotate(45deg);
  position: absolute;
  top: -11px;
  background: #fff;
  border-bottom: 0;
  border-right: 0;
}

.cuo {
  width: 30px;
  height: 30px;
  font-size: 36px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: red;
  color: #fff;
  margin: 10px;
}

.value {
  min-width: 50px;
  max-width: 185px;
  height: 22px;
  padding: 0 5px;
  font-weight: 600;
  border-radius: 25px;
  display: inline-block;
  border: 1px solid #b5b3b6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.commentList {
  height: 112px;
  width: 100%;
  padding: 5px 10px 0 10px;
  overflow: auto;
}

.Confirmation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  .Confirm {
    background: #fff;
    width: 300px;
    height: 150px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.comments1 {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.comments2 {
  width: 100%;
  height: 100vh;
}

.searchlist {
  position: absolute;
  width: 90%;
  height: 108px;
  overflow: auto;
  top: 40px;
  left: 20px;
  background: #fff;
  z-index: 10;
  border: 1px solid #dadada;
  font-size: 10px;

  >li {
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-bottom: 2px solid #f7f7f7;
  }

  >li:nth-of-type(2) {
    background: #ffe2b0 !important;
    border-bottom: 2px solid #ffe2b0;
  }

  >li:hover {
    background: #f7f7f7;
  }
}

.commentx {
  position: absolute;
  top: 3px;
  right: 0px;
  font-size: 44px;
  cursor: pointer;
}

.clear {
  color: #000;
  font-size: 24px;
  font-weight: 900;
  cursor: pointer;
}

.ButtonList,
.ButtonListCustom {
  border: 0;
  background: rgb(244, 156, 45);
  color: #fff;
  min-width: 60px;
  height: 32px;
  border-radius: 60px;
  display: flex;
  cursor: move;
  padding: 0 10px;
  margin: 5px;

  >p {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
  }

  >button {
    margin-left: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 0;
    background: rgba(0, 0, 0, 0);
  }
}

.ButtonListCustom {
  background: rgb(149, 197, 17);
  position: relative;
}

.triangle {
  border: 5px solid #fff;
  border-top: 5px solid rgb(244, 156, 45);
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.commentsButtonList {
  width: 120px !important;
  border-radius: 5px !important;
  color: #000 !important;
  background: rgb(235, 233, 235) !important;
  margin: 5px !important;
}

.commentsClassList {
  margin: 5px !important;
  border-radius: 5px !important;
  color: #fff !important;
  position: relative !important;
}

.commentsClass {
  margin: 5px !important;
  width: 200px !important;
  border-radius: 5px !important;
}

.attrClassList,
.activeAttrClassList {
  width: 120px !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 5px !important;
  background-color: #666;
}

.activeAttrClassList {
  background-color: rgb(244, 156, 45);
}

.Input {
  height: 24px;
  width: 100%;
  border: 0 !important;
}

.commentsCard {
  width: 91% !important;
  background: #fff !important;
  margin: 50px 60px !important;
  position: relative !important;
  height: 80% !important;
  display: flex !important;
  flex-direction: column !important;
}

.commentsContent {
  display: flex !important;
  flex-wrap: wrap !important;
  border: 1px solid #323232 !important;
  border-radius: 5px !important;
  margin: 0 5px !important;
  min-height: 60px !important;
  flex-direction: column;
}

.commentsSearch {
  display: flex;
  align-items: center;
  position: relative;
}

.SortableList {
  display: block !important;
  white-space: nowrap !important;
  border: 0 !important;
  background-color: transparent !important;
  position: relative !important;
  z-index: 0 !important;
  border-radius: 3px !important;
  outline: none !important;
}

.SortableItem {
  float: left !important;
  background: transparent !important;
  display: flex !important;
  box-sizing: border-box !important;
  user-select: none !important;
  cursor: move !important;
  z-index: 10 !important;
}

.evaluate {
  font-size: 14px !important;
  width: 90% !important;
  height: 30px !important;
  border-radius: 3px !important;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ShowComments {
  // min-height: 22px !important;
  width: 100%;
  border: 0 !important;
  padding: 5px 8px;
  display: block !important;
  border-radius: 25px;
  margin: 0 0 5px 0;
  color: #fff;
  word-break: break-all;
}

.answer {
  margin: 15px 0 3px 10px !important;
  height: 16px !important;
  color: #666666 !important;
  margin-left: 3px !important;
  width: 110% !important;
}

.answerMargin {
  margin: 15px 0 3px 10px !important;
}

.common_cancel {
  background: #fff !important;
  border: 1px solid #ff9f37 !important;
  color: #ff9f37 !important;
  border-radius: 5px !important;
  width: 100px !important;
}

.confirm {
  background: #ff9f37 !important;
  color: #fff !important;
  border-radius: 5px !important;
  width: 100px !important;
}

.maskCardRow {
  position: absolute !important;
  width: 90% !important;
  height: 750px !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: auto !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 15px !important;
  padding: 35px 5px 15px 5px !important;
  z-index: 10 !important;
}

.ul {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: column !important;
  overflow: auto !important;
}

.img2 {
  width: 50px !important;
  height: 50px !important;
  margin: 5px 0 !important;
}

.ListCodeCardColumn {
  width: 223px !important;
  min-height: 330px !important;
  max-height: 380px !important;
  overflow: hidden !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  background: #fff !important;
  border-radius: 3px !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
  transition: 0.5s !important;
  margin: 0 10px 20px 10px !important;
  flex: none !important;
}

.ResultsCardColumn {
  max-width: 223px !important;
  width: 223px !important;
  min-height: 320px !important;
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  background: #fff !important;
  display: flex !important;
  font-size: 18px !important;
  position: relative !important;
  flex: none !important;
  margin: 0 10px 30px 10px !important;
  border-radius: 10px !important;
  flex-direction: column !important;
  align-items: center !important;
}

.ResultsCardRow {
  background: rgba(0, 0, 0, 0);
  padding: 0 15px !important;
  margin-top: 10px !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  background-color: #f7f7f7 !important;
}

.tasting_finish_taste {
  color: #fff !important;
  background: #05a463 !important;
  width: 145px !important;
  padding: 0 10px !important;
  height: 50px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.p1 {
  font-weight: 600 !important;
  text-align: center !important;
}

.p2 {
  width: 95% !important;
  text-align: center !important;
  border-radius: 5px !important;
  line-height: 35px !important;
  min-height: 35px !important;
  background: #f7f8fa !important;
  margin: 10px 0 !important;
  font-size: 16px !important;
  color: #555555 !important;
  padding: 0 10px !important;
}

.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.back {
  border-radius: 40px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 40px;
  height: 40px;
  padding: 4px 0px 0px 0px;
}

.goback {
  border-radius: 40px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 40px !important;
  height: 40px;
  padding: 4px 0px 0px 0px;
}

.alert {
  color: #f49b00;
  font-size: 19px;
  margin: 0 2px;
}

.cardRow_cardRow__HeyqC {
  background-color: #f7f7f7 !important;
}

.showModal {
  margin: 80px auto !important;
}

.cardRowModal {
  flex-flow: row wrap !important;
  justify-content: space-evenly;
  justify-items: center;
  text-align: center;
  margin-bottom: 3em;
  margin-top: 5em;
}

.h1Title {
  align-self: center;
  font-size: 35px !important;
  justify-content: center;
  text-align: center;
}

.h3Title {
  align-self: center;
  font-size: 20px !important;
  justify-content: center;
  text-align: center;
}

.buttonsWrapper {
  flex-direction: row;
  align-self: center;
  padding: 10px;
}

.header {
  display: flex;

  .back {
    border-radius: 40px;
    background-color: #f49b00;
    color: #fff;
    font-weight: bold;
    width: 40px;
    height: 40px;
    padding: 4px 0px 0px 0px;
  }
}

.countDownDiv {
  position: absolute;
  right: -10px;
  top: 0px;
}

.countDownColumn {
  position: relative;
}

.checke {
  position: relative;
  -webkit-appearance: none;
  width: 52px;
  height: 26px;
  line-height: 26px;
  background: #dddddd;
  border-radius: 26px;
  outline: none;
}

.checke:hover {
  cursor: pointer;
}

.checke:before {
  position: absolute;
  top: 2px;
  left: 2px;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 5px #ddd;
  transition: all 0.2s linear;
}

.checke:checked {
  background: #fa930d;
}

.checke:checked:before {
  left: 28px;
  transition: all 0.2s linear;
}

.code {
  font-size: 20px;
}

.minScreen {
  display: none !important;
}

@media screen and (max-width: 1000px) and (min-width: 501px) {
  .title {
    font-size: 20px;
  }

  .int_round {
    margin: 10px 0 !important;
  }

  .card {
    padding: 0 2px;
    justify-content: space-around;
  }

  .ListCodeCardColumn {
    width: calc(28vw + 20px);
    margin: 4px 0 4px !important;
    height: 280px;
  }

  .ResultsCardColumn {
    width: 28vw;
  }

  .searchInput {
    width: auto;
  }

  .headers {
    .right {
      padding: 0 !important;
      margin: 10px !important;
    }
  }

  .commentsCard {
    margin: 1vh auto !important;
    width: 96vw !important;
    height: 98vh !important;
  }

  .comments1 {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    display: flex;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
}

@media screen and (max-width: 1000px) and (max-height: 1000px) {
  .IntensityScale {
    flex: 1 !important;
    min-height: auto;
    overflow: hidden !important;
    margin: 4px 0;
  }

  .maxScreen {
    display: none !important;
  }

  .minScreen {
    display: flex !important;

    .NotFinishButton,
    .finishButton {
      font-size: 12px;
      width: auto;
      height: auto;
      padding: 8px
    }
  }

  .minScreenSample {
    padding-top: 10px !important;
    justify-content: space-around;

    .selectedCard,
    .setCard {
      width: 30vw;
      margin: 6px !important;
    }
  }

  .showModal {
    margin: 4px auto !important;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 20px;
  }

  .int_round {
    margin: 10px 0 !important;
  }

  .card {
    padding: 0 2px;
    justify-content: space-around;
  }

  .ListCodeCardColumn {
    width: calc(41vw + 20px);
    margin: 4px 0 4px !important;
  }

  .ResultsCardColumn {
    width: 41vw;
  }

  .searchInput {
    width: auto;
  }

  .headers {
    .right {
      padding: 0 !important;
      margin: 10px !important;
    }
  }

  .commentsCard {
    margin: 1vh auto !important;
    width: 95vw !important;
    height: 98vh !important;
  }

  .comments1 {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    display: flex;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .minScreen {
    display: flex;

    .NotFinishButton,
    .finishButton {
      font-size: 12px;
      width: auto;
      height: auto;
      padding: 8px
    }
  }

  .buttonRow {
    padding: 0 0 0 4px !important;
    font-size: 14px;
  }

  .minScreenSample {
    padding-top: 10px !important;
    justify-content: space-around;

    .selectedCard,
    .setCard {
      width: 44vw;
      margin: 6px !important;
    }
  }

  .attrClassList,
  .activeAttrClassList,
  .commentsButtonList,
  .commentsClassList {
    width: 26vw !important;
  }
}