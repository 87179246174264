@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.container {
  background-color: '#efefef';
  flex: 1;
  flex-direction: 'row';
  flex-wrap: 'wrap';
}

.filter {
  flex: 1;
  padding: 4;
}

.filterTitle {
  font-size: 10px;
}

.filterInput {
  margin-top: 4px;
  border-radius: 50px;
}

.filterWrapper {
  background-color: '#FFF';
  height: 55px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-color: '#efefef';
  flex-direction: row;
  justify-content: 'space-between';
  flex-wrap: 'wrap';
}

.dateInput {
  max-width: 50px;
  border-radius: 50px;
  background-color: '#efefef';
  padding: 5px;
  height: 27px;
  flex: 1;
  border-color: '#FFF';
}

.picker {
  border-radius: 50px;
  background-color: '#efefef';
  border-color: '#efefef';
  padding: 5px;
  height: 25px;
}

.textInput {
  border-radius: 50px;
  background-color: '#efefef';
  padding: 5;
  height: 25px;
}

.roundPickerBorders {
  border-radius: 50px;
  overflow: 'hidden';
  height: 25px;
  margin-top: 4px;
}

.dateClear {
  position: 'absolute';
  right: 15px;
  color: '#999';
  top: 28px;
}

.iconPicker {
  margin-top: 6px;
  margin-right: 6px;
}

.errorContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  h1 {
    padding-top: 20px;
    font-size: 18px;
  }
}

.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.back {
  border-radius: 40px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 40px;
  height: 40px;
  padding: 4px 0px 0px 0px;
}

.sortableDiv {
  float: left;
  padding: 8px;
  background: transparent;
  border: 0;
  display: flex;
  box-sizing: border-box;
  user-select: none;
  color: #fff;
  font-weight: 400;
  cursor: move;
  margin: 5px;
  align-items: center;
  border-radius: 60px;
  height: 32px;
}

.sortableButton {
  text-align: center;
  width: 26px;
  height: 30px;
  z-index: 99999;
  cursor: pointer;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  align-items: center;
  background: transparent;
}

.sortableListDiv {
  display: block;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
  position: relative;
  z-index: 0;
  border-radius: 3px;
  outline: none;
}

.buttonProfile {
  border-radius: 5px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 100%;
}

.buttonFinish {
  border-radius: 5px;
  background-color: #95c511;
  color: #fff;
  font-weight: bold;
  width: 100%;
}

.commentsDiv {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.commentDiv {
  padding: 0px 5px;
  width: 100%;
}

.commentButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: auto;
  padding: 10px;
  margin: 0 5px;
}

.buttonBackGround {
  width: 100%;
  height: 100%;
}

.showFull {
  width: 100%;
  height: 100%;
}

.checke {
  position: relative;
  -webkit-appearance: none;
  width: 52px;
  height: 26px;
  line-height: 26px;
  background: #dddddd;
  border-radius: 26px;
  outline: none;
}

.checke:hover {
  cursor: pointer;
}

.checke:before {
  position: absolute;
  top: 2px;
  left: 2px;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 5px #ddd;
  transition: all 0.2s linear;
}

.checke:checked {
  background: #fa930d;
}

.checke:checked:before {
  left: 28px;
  transition: all 0.2s linear;
}