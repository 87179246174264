@import '../../colors.scss';

.headerDropDown {
  z-index: 100;
  position: absolute;
  top: 60px;
  right: 12px;
  border: 1px solid #ccc;
  background-color: white;
  width: max-content;
}

.headerDropDown > ul > li {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 0.8em;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  height: 36px;
}

.pictureInput {
  display: none;
}
.languageSelectBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }
  .languageSelect {
    display: flex;
    align-items: center;
    cursor: pointer;
    .dropdownArrow {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #999;
    }
  }
  .languageItemBox {
    position: absolute;
    top: 35px;
    background: #fff;
    width: 66px;
    right: 20px;
    border: 1px solid #ccc;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;
      border-bottom: 1px solid #ccc;
      cursor: pointer;
      span {
        flex: 1;
      }
    }
    li:last-child {
      border: none;
    }
  }
}