body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 0.8em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.list_process_check .__react_component_tooltip {
  max-width: 600px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.TransformComponent-module_container__3NwNd {
  height: 100% !important;
  width: 100% !important;
  overflow: scroll;

  .TransformComponent-module_content__TZU5O {
    height: 100% !important;
    width: 100% !important;
    overflow: scroll;
  }
}

.ant-picker-outlined.ant-picker-disabled {
  background: rgba(239, 239, 239, 0.3);
}

.ant-picker-input>input[disabled] {
  color: #666 !important;
}