.sprintPatchNotesContainer {
  display: flex;
  justify-items: flex-start;
  flex-flow: column;

  width: 100%;

  margin: 20px 0;
}

.patchNotesHeaderLabel {
  font-size: 18px;
}

.buttonAddNoteContainer {
  display: flex;
  justify-content: center;
}

.buttonAddNote {
  font-size: 24px;
  line-height: 20px;
  width: 42px;
}
