.container {
  display: flex;
}

.taster {
  width: 250px;
}

.tasterHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tasterComments {
  align-items: center;
  overflow-y: auto;
}

.comment {
  display: flex;
  flex-direction: row;
  background-color: #f49b00;
  color: #030303;
  width: 100%;
  padding: 5px 10px;
  justify-content: center;
  margin: 3px 0px;
  border-radius: 25px;
}

.commentText {
  font-weight: 400;
  color: #030303;
  text-align: center;
}