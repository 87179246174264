@import '../../colors.scss';

.HMS {
  color: #000 !important;
  width: 175px !important;
  height: 50px !important;
  font-size: 18px !important;
  margin-right: 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 15px;
  border: 1px solid #f5e003;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  .countdown {
    color: #000 !important;
  }
  .funnel {
    height: 30px;
    width: 18px;
  }
  .font {
    font-size: 12px;
    color: #000 !important;
    margin-right: 6px;
    margin-left: 2px;
  }
  .font:last-of-type {
    margin-right: 0;
  }
}
@keyframes countdown {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  99.99% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.animation {
  animation: countdown 1s linear infinite;
}
.endAnimation {
  transform: rotate(180deg);
}
