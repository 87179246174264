@import '../../../colors.scss';

.title,
.code {
  font-size: 24px;
  color: #bdc4c7;
  font-weight: 600;
}

.headers {
  background: #f7f7f7 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .center {
    flex: none !important;
    display: flex;
    align-items: center;
    font-size: 19px;
    color: #666666;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }
}

.code {
  font-size: 20px;
}

.int_round {
  background: #f7f7f7 !important;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #f6b45e;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.card {
  background: #f7f7f7;
  padding: 0 16px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap !important;
}

.img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 0 10px;
  margin-left: -8px;
  width: 105%;

  img {
    width: 45px;
    height: 45px;
  }

  span {
    word-break: break-all
  }
}

.tasting {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-left: -8px;
  width: 107%;
}

.updown {
  list-style: none;
}

.updown:hover {
  cursor: pointer;
}

.Button,
.Button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 0;
}

.Button1 {
  margin: 15px 0 0 0;
}

.p1 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.p2 {
  font-size: 18px;
}

.hh {
  font-size: 20px;
  margin: 0 0 0 5px;
}

.hr {
  border: 0.6px solid rgba(0, 0, 0, 0.06);
}

.touxiang {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.off {
  color: #f6b45e;
  font-size: 30px;
  width: 35px;
  height: 35px;
  border: 1px solid #768198;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  padding-top: 2px;
  margin: 6px;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  width: 100%;
  height: 100%;
  z-index: 5;
}

.flaovrlist {
  width: 35%;
  min-width: 300px;
  display: flex;
  border-top: 2px solid #ffcdcf;
  border-bottom: 2px solid #ffcdcf;
  margin: 0 10px;
  margin-bottom: -2px;
  overflow-x: auto;
  align-items: center;
  padding-left: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.dui {
  color: #96c80d;
  font-size: 40px;
  text-align: center;
  margin: 5px 0;
}

.sanjiao {
  width: 22px;
  height: 22px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  transform: rotate(45deg);
  position: absolute;
  top: -11px;
  background: #fff;
  border-bottom: 0;
  border-right: 0;
}

.cuo {
  width: 30px;
  height: 30px;
  font-size: 36px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: red;
  color: #fff;
  margin: 10px;
}

.value,
.valueLarge {
  min-width: 50px;
  max-width: 210px;
  max-height: 40px;
  min-height: 20px;
  line-height: 18px;
  padding: 0 5px;
  font-weight: 600;
  border-radius: 25px;
  border: 1px solid #b5b3b6;
  margin-left: -11px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.valueLarge {
  font-size: 0.9em;
}

.commentList {
  height: 112px;
  width: 100%;
  overflow: auto;
}

.Confirmation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  .Confirm {
    background: #fff;
    width: 300px;
    height: 150px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.comments1 {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.comments2 {
  width: 100%;
  height: 100%;
}

.searchlist {
  position: absolute;
  width: 90%;
  height: 108px;
  overflow: auto;
  top: 40px;
  background: #fff;
  z-index: 10;
  border: 1px solid #dadada;
  font-size: 10px;

  >li {
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    border-bottom: 2px solid #f7f7f7;
  }

  >li:nth-of-type(2) {
    background: #ffe2b0 !important;
    border-bottom: 2px solid #ffe2b0;
  }

  >li:hover {
    background: #f7f7f7;
  }
}

.commentx {
  position: absolute;
  top: 3px;
  right: 0px;
  font-size: 44px;
  cursor: pointer;
}

.clear {
  color: #000;
  font-size: 24px;
  font-weight: 900;
  cursor: pointer;
}

.ButtonList,
.ButtonListCustom {
  border: 0;
  background: rgb(244, 156, 45);
  color: #fff;
  min-width: 60px;
  height: 32px;
  border-radius: 60px;
  display: flex;
  cursor: move;
  padding: 0 10px;
  margin: 5px;

  >p {
    display: flex;
    align-items: center;
    padding-bottom: 3px;
  }

  >button {
    margin-left: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 0;
    background: rgba(0, 0, 0, 0);
  }
}

.ButtonListCustom {
  background: rgb(149, 197, 17);
  position: relative;
}

.triangle {
  border: 5px solid #fff;
  border-top: 5px solid rgb(244, 156, 45);
  position: absolute;
  bottom: -10px;
  left: 50%;
}

.commentsButtonList {
  width: 120px !important;
  border-radius: 5px !important;
  color: #000 !important;
  background: rgb(235, 233, 235) !important;
  margin: 5px !important;
}

.commentsClassList {
  margin: 5px !important;
  border-radius: 5px !important;
  color: #fff !important;
  position: relative !important;
}

.commentsClass {
  margin: 5px !important;
  width: 200px !important;
  border-radius: 5px !important;
}

.attrClassList {
  width: 120px !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 5px !important;
}

.Input {
  height: 24px;
  width: 100%;
  border: 0 !important;
}

.commentsCard {
  width: 91% !important;
  background: #fff !important;
  margin: 50px 60px !important;
  position: relative !important;
  height: 80% !important;
  display: flex !important;
  flex-direction: column !important;
}

.commentsContent {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #323232;
  border-radius: 5px;
  margin: 0 5px;
  min-height: 60px;
  flex-direction: column;
}

.commentsSearch {
  display: flex;
  align-items: center;
  position: relative;
}

.SortableList {
  display: block !important;
  white-space: nowrap !important;
  border: 0 !important;
  background-color: transparent !important;
  position: relative !important;
  z-index: 0 !important;
  border-radius: 3px !important;
  outline: none !important;
}

.SortableItem {
  float: left !important;
  background: transparent !important;
  display: flex !important;
  box-sizing: border-box !important;
  user-select: none !important;
  cursor: move !important;
  z-index: 10 !important;
}

.evaluate {
  font-size: 14px !important;
  width: 98% !important;
  height: 30px !important;
  border-radius: 3px !important;
  color: #fff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ShowComments {
  width: 100%;
  border: 0 !important;
  padding: 5px 8px;
  display: block !important;
  border-radius: 25px;
  margin: 0 0 5px 0;
  color: #fff;
  word-break: break-all;
}

.answer {
  margin: 15px 0 3px 10px !important;
  height: 16px !important;
  color: #666666 !important;
  margin-left: 3px !important;
  width: 110% !important;
}

.answerMargin {
  margin: 15px 0 3px 10px !important;
}

.common_cancel {
  background: #fff !important;
  border: 1px solid #ff9f37 !important;
  color: #ff9f37 !important;
  border-radius: 5px !important;
  width: 100px !important;
}

.confirm {
  background: #ff9f37 !important;
  color: #fff !important;
  border-radius: 5px !important;
  width: 100px !important;
}

.masks {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(12, 12, 12, 0.3) !important;
  z-index: 10;

  .maskCardRow {
    position: fixed;
    width: 90%;
    height: calc(100vh - 80px);
    left: 0 !important;
    top: 0px !important;
    bottom: 0 !important;
    right: 0 !important;
    margin: auto !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
    padding: 70px 5px 15px 5px !important;
    z-index: 10 !important;
  }
}

.ul {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  flex-direction: column !important;
  overflow: auto !important;
}

.img2 {
  width: 50px !important;
  height: 50px !important;
  margin: 5px 0 !important;
}

.ListCodeCardColumn {
  width: 230px;
  min-height: 330px;
  max-height: 380px !important;
  overflow: hidden !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
  background: #fff !important;
  border-radius: 3px !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06) !important;
  transition: 0.5s !important;
  margin: 0 10px 20px 10px !important;
  flex: none !important;
}

.ResultsCardColumn {
  width: 230px;
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  background: #fff !important;
  display: flex !important;
  font-size: 18px !important;
  position: relative !important;
  flex: none !important;
  margin: 0 10px 30px 10px !important;
  border-radius: 10px !important;
  flex-direction: column !important;
  align-items: center !important;
}

.ResultsCardRow {
  background: rgba(0, 0, 0, 0);
  margin-top: 10px !important;
  justify-content: center !important;
  background-color: #f7f7f7 !important;
}

.tasting_finish_taste {
  color: #fff !important;
  background: #05a463 !important;
  width: 145px !important;
  padding: 0 10px !important;
  height: 50px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
}

.p1 {
  font-weight: 600 !important;
  text-align: center !important;
}

.p2 {
  width: 95% !important;
  text-align: center !important;
  border-radius: 5px !important;
  line-height: 35px !important;
  min-height: 35px !important;
  background: #f7f8fa !important;
  margin: 10px 0 !important;
  font-size: 16px !important;
  color: #555555 !important;
  padding: 0 10px !important;
  word-break: break-all;
}

.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.back {
  border-radius: 40px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 40px;
  height: 40px;
  padding: 4px 0px 0px 0px;
}

.goback {
  border-radius: 40px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 40px !important;
  height: 40px;
  padding: 4px 0px 0px 0px;
}

.alert {
  color: #f49b00;
  font-size: 19px;
  margin: 0 2px;
}

.cardRow_cardRow__HeyqC {
  background-color: #f7f7f7 !important;
}

.checke {
  position: relative;
  -webkit-appearance: none;
  width: 52px;
  height: 26px;
  line-height: 26px;
  background: #dddddd;
  border-radius: 26px;
  outline: none;
}

.checke:hover {
  cursor: pointer;
}

.checke:before {
  position: absolute;
  top: 2px;
  left: 2px;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 5px #ddd;
  transition: all 0.2s linear;
}

.checke:checked {
  background: #fa930d;
}

.checke:checked:before {
  left: 28px;
  transition: all 0.2s linear;
}

.searchDiv {
  position: absolute;
  left: 10px;
  top: 0px;
  display: flex;
  align-items: center;
}

.searchInput {
  width: calc(100vw - 550px);
  max-width: 580px;
  height: 40px;
  margin: 14px 0px 14px 10px;
  box-sizing: border-box;
  padding-left: 40px;
  border: 1px solid #b3b3b3;
  border-radius: 6px;
  background-color: #f7f7f7;
  background-image: url('../../../assets/Search.png');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 10px;
}

@media screen and (max-width: 1000px) and (min-width: 501px) {
  .title {
    font-size: 20px;
  }

  .card {
    padding: 0 2px;
    justify-content: space-around;
  }

  .ListCodeCardColumn {
    width: calc(28vw + 20px);
    margin: 4px 0 4px !important;
    height: 280px;
  }

  .ResultsCardColumn {
    width: 28vw;
  }

  .searchInput {
    width: auto;
  }

  .headers {
    .right {
      padding: 0 !important;
      margin: 10px !important;
    }
  }

  .commentsCard {
    margin: 1vh auto !important;
    width: 96vw !important;
    height: 98vh !important;
  }

  .comments1 {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    display: flex;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
}

@media screen and (max-height: 1000px) and (max-width: 1000px) {
  .IntensityScale {
    flex: 1 !important;
    min-height: auto;
    overflow: hidden !important;
    margin: 4px 0;
  }

  .maskCardRow {
    width: 100% !important;
    height: 100% !important;
  }

  .commentsContent {
    padding: 4px !important;
  }

  .ButtonList,
  .ButtonListCustom {
    margin: 2px !important;
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 20px;
  }

  .card {
    padding: 0 2px;
    justify-content: space-around;
  }

  .ListCodeCardColumn {
    width: calc(41vw + 20px);
    margin: 4px 0 4px !important;
  }

  .ResultsCardColumn {
    width: 41vw;
  }

  .searchInput {
    width: auto;
  }

  .headers {
    .right {
      padding: 0 !important;
      margin: 10px !important;
    }
  }

  .commentsCard {
    margin: 1vh auto !important;
    width: 95vw !important;
    height: 98vh !important;
  }

  .comments1 {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    display: flex;
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .attrClassList,
  .activeAttrClassList,
  .commentsButtonList,
  .commentsClassList {
    width: 26vw !important;
  }

  .img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    margin-left: -8px;
    width: 105%;

    img {
      width: 35px;
      height: 35px;
    }

    span {
      width: 100px;
      word-break: break-all
    }
  }
}