@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
}

.pageContainerOptions {
  flex-direction: row;
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
}

.droppableCard {
  background-color: $normalColor;
  margin-top: -10px;
  padding: 10px;
}

.buttonsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.samplesCategories {
  width: 183px;
  height: 97px;
  background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 55px;

  span {
    font-weight: 700;
    font-size: 14px;
  }
}

.samplesCategorieOptions {
  width: 166px;
  height: 97px;
  background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 50px;
}

.optionSet {
  flex: 1;
  display: flex;
  justify-content: center;
}

.optionSelect {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.checkboxHorizontal {
  margin-bottom: 5px;
}

.divButtonSave {
  display: flex;
  padding-right: 30px;
  top: 42px;
  position: relative;
}

.ConcentrationFlavor {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 55px;
  height: 38px;
  font-size: 1em;
  max-width: 183px;
  flex: 1.1;
}

.tooltip {
  white-space: normal;
}

.helpCircleId {
  color: $secondaryText;
  height: 17px;
  margin-left: 5px;
}
