@import "../../colors.scss";
.buttonShow {
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 12px;
}

.borderStyle {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  border-right: 1px solid #5c5b59;
  padding: 0px;
}
.borderStyleProfile {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  padding: 0px;
}
.textScore {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 6px;
  text-align: "center";
}
.textScoreProfile {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 6px;

  text-align: "center";
}

.arrow {
  width:10px;
  margin:10px;  
}

.line {  
  margin-top: 91px;
  width:20px;
  height:calc(100% - 101px);
  float:left;
  position: relative;
  background: linear-gradient(180deg, #33cc33 0%, #99ff66 35%, #ffff66 50%, #ffff66 65%, red 80%, #c00000 90%);
  
}

.line::after{
  content: '';
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 30px solid #c00000;
  position: absolute;
  bottom: -30px;
  left: -5px;  
  transform: rotate(90deg);
}

