@import './../../colors.scss';

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid $secondaryColor;
  width: 36px;
  height: 36px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}