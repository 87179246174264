@import '../../colors.scss';

.header {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50px;

  position: absolute;
  top: -1px;

  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 5px 14px -2px #000;
  background-color: white;

  z-index: 1;

  > button {
    position: absolute;
    left: 0;

    margin-left: 16px;
  }

  @media (max-width: 700px) {
    justify-content: space-between;

    height: 40px;

    padding: 0 16px;

    > button {
      position: unset;
      left: unset;

      margin-left: 0;
    }
  }
}

.contentContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;

  padding: 60px 10px 20px 10px;

  user-select: none;

  .react-pdf__Page {
    box-shadow: 0px 5px 14px -2px #000;
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }
}

.groupButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .ant-btn {
    min-width: 10px !important;
  }
}

.page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px 5px;
}

.imagePreview {
  width: 100%;
  resize: contain;
  margin-top: 20px;
  border: 1px solid #000;
}

.inputPageWrapper {
  > input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;

    border: 1px solid #00000020;
    width: 45px;

    padding: 0 4px;
    margin: 0 5px;

    border-radius: 2px;
  }

  > input:hover,
  > input:focus {
    border: 1px solid #000;
    padding: 0 4px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.close{
	cursor: pointer;
	background-color: $normalText;
	height: 35px;
	width: 35px;
	color: #fff;
	border: 1px solid $normalText;
	border-radius: 4px;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
  float: right;
  margin: '60px';
  background: rgb(244, 155, 0);
  border-color: rgb(244, 155, 0);
}

.maximize{
  cursor: pointer;
	background-color: $normalText;
	height: 35px;
	width: 35px;
	color: #fff;
	border: 1px solid $normalText;
	border-radius: 4px;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
  float: right;
  background: rgb(244, 155, 0);
  border-color: rgb(244, 155, 0);
  float: right;
  margin: 60px,
}


.Box>div:nth-child(1){
  width: 60vw ;
  height: 100% ;

}
.Box>div:nth-child(1)>svg:nth-child(1){
 width: 60vw ;
 height: 90% ;
}

.buttonBackGround{
  width: 100%;
  height: 100%,
}