@import '../../colors.scss';

.dropdownContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.dropdown {
  color: $normalText !important;
  margin-top: 8px;
  outline: none;
}

.dropdownControl {
  color: $normalText !important;
  border: 1px solid $secondaryBorder !important;
  border-radius: 4px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropdownMultiOuterList {
  height: 100vh;
  width: 100vw;
  background-color: none;
  position: fixed;
  left: 0;
  top: 0;
}

.dropdownMultiContainer {
  position: relative;
  flex: 1;
}

.labelContainer {
  display: flex;
  width: 100%; 
}

.hintContainer {
  height: 20px; 
  position: relative;
}

.spinner {
  position: relative;
  top: -1px;
  pointer-events: none;
  div {
    width: 20px;
    height: 20px;
    margin: 0 30px;
    
  }
}
.dropdownMultiList {
  background-color: $normalColor;
  border: 1px solid $secondaryBorder;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -2px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.dropdownMultiOption {
  box-sizing: border-box;
  color: $normalText;
  display: block;
  padding: 0px;
  border-bottom: solid 2px $primaryBorder;
}

.dropdownMultiOption:hover {
  background-color: $primaryBorder;
  color: $normalText;
}

.dropdownMultiContainer svg {
  color: $secondaryText;
  position: absolute;
  left: 5px;
  top: 6px;
}

.dropdownMultiSelectionContainer {
  display:flex;
  align-items: center;
  padding-left: 5px;
  border: 1px solid $secondaryBorder;
  border-radius: 4px;
  color: $normalText;
  margin-top: 8px;
  min-height: 40px;
  max-height: 200px;
  overflow-y: auto;
  outline: none;
  flex-wrap: wrap;
  background-color: $normalColor;
}

.disabledContainer {
  background-color: $secondaryBorder;
}

.dropdownArrow {
  position: absolute;
  right: -3px;
  top: 26px;
  width: 28px;
  height: 40px;
  background-image:
    linear-gradient(45deg, transparent 50%, #999 50%),
    linear-gradient(135deg, #999 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}