@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.droppableCard {
  background-color: $normalColor;
  margin-top: -10px;
  padding: 10px;
}

.buttonsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box {
  display: flex;
  align-items: center;
}

.buttonsRowLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.splitItem {
  margin: 26px 0 10px 0;
  border: 1px solid $secondaryBorder;
  border-radius: 2px;
  padding: 14px;
  flex-direction: column;
}

.splitItemTitle {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.splitItemTitleName {
  flex: 1;
  height: 66px;
  display: flex;
}

.nameNum {
  font-weight: 600;
  font-size: 18px;
  line-height: 66px;
  margin-right: 40px;
}

.nameOptsText {
  font-size: 14px;
  line-height: 19px;
}

.splitItemTitleOperation {
  flex: 1;
  height: 66px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nameOpts {
  flex: 1;
}

.nameOptstitle {
  display: flex;
  align-items: center;
}

.editBtn {
  position: relative;
  width: 20px;
  height: 20px;
  left: -25px;
  top: 5px;
  cursor: pointer;
}

.nameOptsInput {
  flex: 1;
  height: 38px;
}

.attention {
  width: 440px;
  min-height: 220px;
  position: fixed;
  left: calc(100vw - 216px - (100vw - 180px) / 2);
  top: 30%;
  padding: 20px;

  .attentionTitle {
    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin: 18px;
  }

  .attentionLittleTitle {
    font-size: 16px;
    line-height: 24px;
    margin: 16px 8px 0 8px;
    color: #656872;
    text-align: center;
  }

  .dropdownArrow {
    top: 10px !important;
  }

  .buttonContainer {
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  .confirmButton {
    border-radius: 5px;
    color: white;
    font-size: 14px;
  }

  .cancelButton {
    background-color: white;
    border: 2px solid #f49b00;
    border-radius: 5px;
    color: #f49b00;
  }
}

.editSamples {
  padding: 10px 10px;
}

.btnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  height: 35px;
  width: 35px;
  color: $normalText;
  border-radius: 4px;
  font-size: 18px;
  position: absolute !important;
  right: -10px;
  top: -10px;
}

input:disabled {
  cursor: not-allowed;
  color: #000;
}

.showListModal {
  display: block;
  position: absolute;
  top: 0;
  z-index: 10;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background: rgba(0, 0, 0, 0.15);
  overflow: auto;

  .closeButton {
    display: flex;
    justify-content: flex-end;
  }

  .showListBox {
    position: fixed;
    width: calc(100vw - 240px) !important;
    top: 80px;
    right: 0 !important;
    margin: auto !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
    padding: 0 5px !important;
    z-index: 10 !important;
    background: #fff;
    left: 180px;
    height: calc(100vh - 100px);
    overflow-y: auto;
    max-height: 470px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}