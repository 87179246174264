@import '../../colors.scss';

.tools{
	position: relative;
	width: 100%;
	padding: 10px 15px;
}

.imageContent{
	position: relative;
	width: 100%;
	height: 100%;
	float: left;
	justify-content: center;
	justify-items: center;
	text-align: center;
	display: flex;
	padding: 10px 5px;
}

.zoomIn{
	cursor: pointer;
	background-color: $secondaryColor;
	height: 35px;
	width: 35px;
	color: #fff;
	border: 1px solid #d28500;
	border-radius: 4px;
	font-size: 18px;
}

.zoomOut{
	cursor: pointer;
	background-color: $secondaryColor;
	height: 35px;
	width: 35px;
	color: #fff;
	border: 1px solid #d28500;
	border-radius: 4px;
	font-size: 18px;
}

.close{
	cursor: pointer;
	background-color: $normalText;
	height: 35px;
	width: 35px;
	color: #fff;
	border: 1px solid $normalText;
	border-radius: 4px;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.handleFullScreen {
    cursor: pointer;
    background-color: $normalText;
    height: 35px;
    width: 35px;
    color: #fff;
    border: 1px solid $normalText;
    border-radius: 4px;
    font-size: 18px;
	display:flex;
    float: left;
    justify-content: center;
	align-items: center;
    background: rgb(244, 155, 0);
	border-color:rgb(244, 155, 0)
}


.showPic{
	width: 100%;
    height: 93vh

}
