.Bg {
  background: url('../../../assets/powerBIBg.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .content {
    margin: 10vh auto;
    width: 50%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
}