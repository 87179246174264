@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.Modal {
  margin: 80px auto !important;
  max-width: 500px;
}

.btnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  height: 35px;
  width: 35px;
  color: $normalText;
  border-radius: 4px;
  font-size: 18px;
}

.helpCircle {
  color: $secondaryText;
  margin-left: 5px;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
}

.buttonLink {
  color: rgba(0, 90, 158, 1);
}

.buttonLink:hover {
  text-decoration: underline;
}

.showListModal {
  display: block;
  position: absolute;
  top: 0;
  z-index: 10;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px);
  background: rgba(0, 0, 0, 0.15);
  overflow: auto;
  .closeButton {
    display: flex;
    justify-content: flex-end;
  }
  .showListBox {
    position: fixed;
    width: calc(100vw - 320px) !important;
    top: 80px;
    right: 0 !important;
    margin: auto !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
    padding: 0 5px !important;
    z-index: 10 !important;
    background: #fff;
    left: 180px;
    height: calc(100vh - 100px);
    overflow-y: auto;
    max-height: 540px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}
