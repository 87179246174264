@import '../../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
}

.pageContainerOptions,
.pagedisabled {
  flex-direction: row;
  display: flex;
  margin-bottom: 10px;
  margin-top: 20px;
  justify-content: space-between;
}

.pagedisabled {
  cursor: not-allowed;
}

.sampleSet {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.droppableCard {
  background-color: $normalColor;
  margin-top: -10px;
  padding: 10px;
}

.buttonsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.samplesCategories {
  width: 20%;
  height: 124px;
  border-radius: 10px;
  opacity: 1;
  justify-content: flex-start;
  margin-left: 25px;
  align-items: center;
  display: flex;

  span {
    display: block;
    width: 67px;
    height: 36px;
    font-size: 30px;
    color: #323232;
    line-height: 30px;
    font-weight: bold;
  }
}

.samplesCategorieOptions,
.selectedAnswer {
  width: 181px;
  height: 124px;
  background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-right: 50px;
  cursor: pointer;
}

.selectedAnswer {
  background-color: #f1f1f1;
  border: 2px solid #f49b00;
}

.optionSelect {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.checkboxHorizontal {
  margin-bottom: 5px;
}

.divButtonSave {
  display: flex;
  position: relative;
  justify-content: flex-end;
  top: 62px;
  right: -10px;
}

.ConcentrationFlavor {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 55px;
  height: 38px;
  font-size: 1em;
  max-width: 183px;
  flex: 1.1;
}

.tooltip {
  white-space: normal;
}

.helpCircleId {
  color: $secondaryText;
  height: 17px;
  margin-left: 5px;
}

.saveSample {
  border-radius: 6px;
  width: 160px;
  margin-left: auto;
  margin-right: 0;
}

.buttonShuffle {
  background-color: #0075ff;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteButton {
  background: #b11f24;
  color: #ffffff;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 10px 20px;
  width: 160px;
  height: 35px;
}

.chevronUp,
.chevronUpDisabled {
  color: #b11f24;
  cursor: pointer !important;
}

.chevronUpDisabled {
  cursor: not-allowed !important;
}

.chevronDownDisabled {
  cursor: not-allowed !important;
}

.chevronDown,
.chevronDownDisabled {
  color: #b11f24;
  cursor: pointer;
  margin-top: 10px;
}

.beerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beerOption {
  margin-top: -28.5px;
  font-weight: 700;
  font-size: 16px;
  color: #000;
}

.Confirmation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  .Confirm {
    background: #fff;
    width: 300px;
    height: 150px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
  }

  .confirmTitle {
    color: #333;
    margin: 25px 0;
  }
}

.common_cancel {
  background: #fff !important;
  border: 1px solid #ff9f37 !important;
  color: #ff9f37 !important;
  border-radius: 5px !important;
  width: 100px !important;
}

.confirm {
  background: #ff9f37 !important;
  color: #fff !important;
  border-radius: 5px !important;
  width: 100px !important;
}

.showListModal {
  display: block;
  position: absolute;
  top: 0;
  z-index: 10;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  background: rgba(0, 0, 0, 0.15);
  overflow: auto;

  .showListBox {
    width: 80%;
    margin: auto !important;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 15px !important;
    padding: 0 5px !important;
    z-index: 10 !important;
    background: #fff;
    position: absolute;
    top: calc((100vh - 100px) / 2);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: calc(100vh - 100px);
    overflow-y: auto;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .closeButton {
    display: flex;
    justify-content: flex-end;
  }
}