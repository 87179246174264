@import '../../colors.scss';

.button {
  border: 0;
  padding: 10px 0;
  border-radius: 60px;
  outline: none;
  width: 120px;
  cursor: pointer;
  font-size: 0.9em;
}
.confirmButton {
  background-color: $secondaryColor;
  color: $normalColor;
}

.neutralButton {
  background-color: $primaryColor;
  color: $normalColor;
}

.removeButton {
  background-color: $primaryColor;
  color: $primaryText;
}
.uploadButton {
  background-color: #3273f5;
  color: $primaryText;
}

.greenButton {
  background-color: #95c511;
  color: $normalColor;
}

.disabledButton {
  background-color: #ccc;
  color: #eee;
  cursor: not-allowed;
}

.roundButton {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: 500;
}

.transparentButton {
  background-color: transparent;
  color: $normalText;
  color: #000;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid $secondaryColor;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.uploadIcon {
  position: absolute;
  left: 123px;
  bottom: 7px;
}
.arrowDown {
  margin-left: 5px;
}
.tooltip {
  white-space: normal;
  max-width: 250px;
}
.arrowUpIcon {
  position: absolute;
  left: 164px;
  bottom: 8px;
}
