@import '../../../colors.scss';

.pageContainer {
  padding: 5px;

  .Screen {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    height: 100%;
    padding: 10px;

    .weekChange {
      margin: 10px 10px 0px 0px;
      flex: 1 1 0%;
      min-width: 127px;

      .weekPicker {
        height: 39px;
        margin-top: 8px;
        width: 100%;
      }
    }

    .days {
      background: #fff;
      border: 1px solid #ededed;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 14px 10px 10px;
      min-height: 600px;

      .plans {

        .planItem {
          width: 100%;
          min-height: 100px;
          border-radius: 10px;
          border: 1px solid #ededed;
          margin-bottom: 10px;
          padding: 16px 20px;
          display: flex;
          align-items: center;
          position: relative;

          .planItemLeft {
            flex: 1;
            margin-top: -10px;
          }

          .planItemRight {
            display: flex;
            align-items: center;

            .user {
              margin: 0 16px 0 30px;
              cursor: pointer;
            }

            .removeButton {
              display: flex;
              width: 30px;
              font-size: 18px;
              justify-content: center;
              align-items: center;
            }

            .allApply {
              position: absolute !important;
              top: 0;
              right: 10px;
            }
          }

          .Session {
            font-size: 22px;
            font-weight: bold;
            margin-right: 20px;
            width: 120px;
          }
        }

        .addCount {
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          margin: 16px 20px;
          width: 140px;

          .addCard {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background: #fff;
            color: #f1ca29;
            border: 2px solid #f1ca29;
            line-height: 15px;
            text-align: center;
            font-size: 25px;
            font-weight: 400;
            margin-right: 6px;
            display: inline-block;
          }
        }
      }

      .control {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .editUsers {
    padding: 10px 10px;
  }

  .btnClose {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    height: 35px;
    width: 35px;
    color: $normalText;
    border-radius: 4px;
    font-size: 18px;
    position: absolute !important;
    right: -10px;
    top: -10px;
  }

  .Modal {
    margin: 80px auto !important;

    .cardRowModal {
      flex-flow: row wrap !important;
      justify-content: space-evenly;
      justify-items: center;
      text-align: center;
      margin-bottom: 3em;
      margin-top: 5em;
    }

    .h3Title {
      align-self: center;
      font-size: 20px !important;
      justify-content: center;
      text-align: center;
    }

    .buttonsWrapper {
      flex-direction: row;
      align-self: center;
      padding: 10px;
      justify-content: space-evenly;
      margin-bottom: 10px;
    }

    .cardRowModal {
      flex-flow: row wrap !important;
      justify-content: space-evenly;
      justify-items: center;
      text-align: center;
      margin-bottom: 3em;
      margin-top: 5em;
    }
  }
}