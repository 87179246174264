@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.buttonContainer {
  text-align: center;
  display: flex;
  justify-content: center;
}
