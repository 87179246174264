.tableGrid {
  display: grid;
  margin: 30px;
  margin-top: 0px;
  border-radius: 5px;
  border: 1px solid grey;
  grid-template-rows: 40px auto;
  grid-template-columns: 200px calc((100% - 200px) / 2) calc((100% - 200px) / 2);
  grid-template-areas:
    "headerScore headerAgeingScale headerAttributes"
    "body body body";
}

.tableGridBody {
  grid-area: body;
}

.scale {
  display: table;
  width: auto;

  .text {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: #08a6f8;
    font-weight: bold;
  }
}

.tooltip {
  white-space: normal;
}

.closeButton {
  position: relative;
  width: 100%;
  padding: 10px 15px;
  justify-content: flex-end;
  display: flex;

  .button {
    cursor: pointer;
    color: #fff;
    border: 1px solid;
    border-radius: 4px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #666666;
  }
}

.scoreGrid {
  display: grid;
  font-weight: bold;
  /**
        grid-template-columns will have the same as the tableGrid in the order.
        If scoreGrid is the first, the width will be 200px. 
        The next one would be "auto". As the example in the class .ageingScaleGrid show us below.
    **/
  grid-template-columns: 200px auto;
}

.ps {
  width: 100%;
  font-weight: bold;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ageingScaleGrid {
  width: 100%;
  display: grid;
  font-weight: bold;
  text-align: center;
  grid-template-columns: auto;
}

.ageingScaleGrid .labelScale {
  border-right: 1px solid black;
  min-width: 30%;
}

#headerScore {
  grid-area: headerScore;
  border-right: 1px solid black;
}

#headerAgeingScale {
  grid-area: headerAgeingScale;
  border-right: 1px solid black;
}

#headerAttributes {
  grid-area: headerAttributes;
}

.firstColumn {
  display: flex;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.scoreNumber {
  width: 50px;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.scoreNumber .labelScale {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 5px 0;
  align-items: center;
  justify-content: center;
}


.scoreNumber label {
  border-bottom: 1px solid black;
}

.scoreNumber label:last-child {
  border-bottom: none;
}

.CategoryScale {
  width: 100%;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.secondColumn {
  display: flex;

  .intensity {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.secondColumn .labelScale {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 5px 0;
  flex-direction: column;
  justify-content: center;
}


#headerScore,
#headerAttributes,
#headerAgeingScale {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid black;
  background-color: rgb(234, 233, 233);
}

.customScaleDiv {
  display: flex;
  min-height: 40px;
  border-bottom: 1px solid black;
}

.noAdminDiv {
  width: 100%;
  display: flex;
  min-width: 65%;
  align-items: center;
  font-weight: normal;
}

.noAdminDiv span {
  color: black;
  font-size: 15px;
  padding-left: 10px;
}

.autoCompleteDiv {
  min-width: 65%;
}

@media screen and (max-width: 1000px) and (min-width: 501px) {
  .modal {
    margin: 1vh auto !important;
    width: 96vw !important;
  }
}

@media screen and (max-width: 500px) {
  .modal {
    margin: 1vh auto !important;
    width: 96vh !important;
    transform: rotate(90deg);
    transform-origin: 0% 0%;
    left: 100vw;
    height: 100vw;
    overflow: auto;
    position: fixed;
  }
}