.modal {
  inset: 40px;
  overflow: auto;

  max-height: 650px;

  position: absolute;
  top: 15%;

  outline: none;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  background: rgb(255, 255, 255);
}

.header {
  padding: 50px 50px 0 50px;
}

.patchNoteItems {
  height: 462px;

  overflow: auto;

  margin: 20px 0;
  padding: 0 50px;
}

.patchNoteItem {
  margin-top: 10px;

  font-size: 18px;
}

.footer {
  display: flex;
  justify-content: right;

  width: 100%;

  position: absolute;
  bottom: 0;
  right: 0;

  padding: 30px;
}
