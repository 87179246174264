@import '../../colors.scss';

$tabBorder: 1px solid $secondaryBorder;

.tabContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
}

.tab {
  background-color: $normalColor;
  border-left: $tabBorder;
  border-top: $tabBorder;
  border-right: $tabBorder;
  border-bottom: $tabBorder;
  border-radius: 4px 4px 0 0;
  font-weight: 500;
  color: $thirdText;
  padding: 12px 31px;
  min-width: 200px;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
}

.tabT {
  background-color: $normalColor;
  border-left: $tabBorder;
  border-top: $tabBorder;
  border-right: $tabBorder;
  border-bottom: $tabBorder;
  border-radius: 4px 4px 0 0;
  font-weight: 500;
  color: $thirdText;
  padding: 12px 5px;
  text-align: center;
  cursor: pointer;
  flex: 1;
  max-width: 220px;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.close svg {
  float: right;
  width: 14px;
  height: 14px;
}

.tabContent {
  background-color: $normalColor;
  border: $tabBorder;
  margin-top: -1px;
  min-height: calc(100% - 170px);
  padding: 15px;
}

.selected {
  border: 1px solid $secondaryColor;
  border-bottom: 2px solid $secondaryColor;
}

.disabled {
  border: 1px solid #c7c7c7;
  border-bottom: 2px solid #d0d0d0;
  background-color: #ececec;
  color: #ababab;
  cursor: not-allowed;
}

.complete {
  color: $primaryText;
  background-color: $secondaryColor;
  border-bottom: 1px solid $secondaryColor;
}

.tabButton,
.tabDisabledBtn {
  color: black;
  border: 1px solid #dadada;
border-radius: 4px 4px 0 0;
  font-weight: 500;
  padding: 10.5px 31px;
  width: 220px;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
  height: 46px;
  border-bottom: 2px solid #dadada;
}

.tabDisabledBtn {
  height: 42px;
  cursor: not-allowed;
  background-color: #ccc;
  color: #eee;
  border: 2px solid transparent;
}

.desc {
  width: 90%;
  overflow: hidden;
  margin-right: 5px;
}
