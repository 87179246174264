.modalBody {
    height: 800px !important;
}

.footerDiv {
    margin-top: 50px;
    margin-right: 30px;
    text-align: end;
}

.modal {
    inset: 40px;
    outline: none;
    padding: 20px;
    overflow: auto;
    max-height: 650px;
    border-radius: 4px;
    position: absolute;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    top: 15%;
}

.modalLabel {
    font-size: '2em';
    margin-left: 30px;
    
}