@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.droppableCard {
  background-color: $normalColor;
  margin-top: -10px;
  padding: 10px;
}

.rowFilters {
  display: flex;
  align-items: flex-end;
  max-width: 700px;
}

.filterCategory {
  width: 350px;
}

.Modal {
  margin: 80px auto !important;
  max-width: 500px;
}

.btnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  height: 35px;
  width: 35px;
  color: $normalText;
  border-radius: 4px;
  font-size: 18px;
}

.helpCircle {
  color: $secondaryText;
  margin-left: 5px;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
}

.buttonLink {
  color: rgba(0, 90, 158, 1);
}

.buttonLink:hover {
  text-decoration: underline;
}

.box {
  display: flex;
  align-items: center;
}

.sortableListEditRoundCategories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addedSamples {
  display: flex;
  align-items: center;
  min-width: 200px;
}

.colorRed {
  color: red;
}

.colorGreen {
  color: green;
}

.attention {
  width: 440px;
  min-height: 220px;
  position: fixed;
  left: calc(100vw - 216px - (100vw - 180px) / 2);
  top: 30%;
  padding: 20px;

  .attentionTitle {
    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin: 18px;
  }

  .attentionLittleTitle {
    font-size: 16px;
    line-height: 24px;
    margin: 16px 8px 0 8px;
    color: #656872;
    text-align: center;
  }

  .dropdownArrow {
    top: 10px !important;
  }

  .buttonContainer {
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }

  .confirmButton {
    border-radius: 5px;
    color: white;
    font-size: 14px;
  }

  .cancelButton {
    background-color: white;
    border: 2px solid #f49b00;
    border-radius: 5px;
    color: #f49b00;
  }
}

.splitItem {
  margin: 26px 0 10px 0;
  border: 1px solid $secondaryBorder;
  border-radius: 2px;
  padding: 14px;
  flex-direction: column;

  .splitItemTitleName {
    flex: 1;
    height: 66px;
    display: flex;
  }

  .nameNum {
    font-weight: 600;
    font-size: 18px;
    line-height: 66px;
    margin-right: 40px;
  }

  .nameOptsText {
    font-size: 14px;
    line-height: 19px;
  }

  .nameOpts {
    flex: 1;
  }

  .nameOptstitle {
    display: flex;
    align-items: center;
  }

  .editBtn {
    position: relative;
    width: 20px;
    height: 20px;
    left: -25px;
    top: 5px;
    cursor: pointer;
  }
}