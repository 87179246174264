@import '../../colors.scss';

.textAreaContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  > textArea {
    border: 1px solid $secondaryBorder;
    border-radius: 4px;
    color: $normalText;
    margin-top: 8px;
    padding: 10px 5px;
    outline: none;
    resize: none;
  }
  .mark {
    position: absolute;
    bottom: 0;
    right: 1px;
    color: #999;
  }
}

