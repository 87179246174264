@import '../../../colors.scss';

.container {
  flex-flow: row wrap !important;
}

.cardRow {
  flex-flow: row wrap !important;
  justify-content: center;
  justify-items: center;
  text-align: center;
}

.cardRowModal {
  flex-flow: row wrap !important;
  justify-content: space-evenly;
  justify-items: center;
  text-align: center;
  margin-bottom: 3em;
  margin-top: 5em;
}

.card {
  overflow: hidden;
  background-color: #fff;
  margin: 5px;
  border-radius: 5px;
  flex-grow: 1;
  border-bottom: 3px solid rgb(222, 222, 222);
  border-left: 3px solid white;
  border-top: 3px solid white;
  border-right: 3px solid white;
  cursor: pointer;
  min-width: 193px !important;
  max-width: 203px !important;
}

.cardBody {
  display: block;
  height: 250px;
  overflow: auto;
}

.cardRevier {
  height: 54px;
  background-color: rgb(222, 222, 222);
  border: 0px;
}

.itemCard {
  background-color: $secondaryColor;
  text-align: center;
  padding: 4px;
  width: 100%;
  color: #ffffff;
  border-radius: 16px;
  margin-bottom: 5px;
}

.beerImage {
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.textTitle {
  color: #000;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 124px;
}

.textSubTitle {
  color: #000;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  //max-width: 124px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
}

.textComment {
  text-transform: capitalize;
  color: white;
  text-align: center;
  align-self: center;
  font-size: 12px;
  word-wrap: break-word;
}

.h1Title {
  align-self: center;
  font-size: 35px !important;
  justify-content: center;
  text-align: center;
}

.h3Title {
  align-self: center;
  font-size: 20px !important;
  justify-content: center;
  text-align: center;
}

.buttonsWrapper {
  flex-direction: row;
  align-self: center;
  padding: 10px;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.noComments {
  font-weight: 500;
  font-size: 12px;
  color: #000;
  align-self: center;
  text-align: center;
}

.Modal {
  margin: 80px auto !important;
}

.finishButton {
  background-color: #95c511;
  border-radius: 5px;
  flex: 1;
  margin-left: 5px;
  color: white;
  font-weight: 500;
  align-self: center;
}

.rank {
  color: $normalText;
  float: right;
  cursor: pointer;
  margin-right: 3px;
  width: 50px;
}

.rankTitles {
  display: flex;
}

.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.back {
  border-radius: 40px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 40px;
  height: 40px;
  padding: 4px 0px 0px 0px;
}

.cardTie {
  border: 3px solid rgba(255, 69, 56, 0.8);
}

.itemListing {
  height: fit-content;
}

.listTable {
  min-width: max-content;

  border-collapse: separate;
  border-spacing: 0px;

  th {
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 3;
    }

    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: #f7f7f7;

    text-align: start;

    outline: 0.7px solid transparent;
    border: 0.5px solid transparent;
  }

  td {
    text-align: left;
    background-color: #f7f7f7;
    border: 0.5px solid transparent;

    &:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }
}

.cardFilter {
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  width: 225px;
  align-items: center;
  max-width: 225px;
  flex-basis: 225px;
  min-width: 225px;

  height: 30px;

  padding: 0;
  border: 2px solid #ccc;
  margin: 5px !important;
}

.filter {
  float: left;
  width: 100%;
  height: 28px;
  border: 0;
  outline: none;
}