@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.pageContainerHeader {
  padding: 0px 15px 20px 10px;
  margin-bottom: 10px;
  border-bottom: solid 2px $primaryBorder;
}

.helpCircle {
  color: $secondaryText;
  margin-left: 5px;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
}

.uploadButton {
  display: flex;
  margin: 40px 10px 0px 0px;
  justify-content: center;
  align-items: center;
}

.zhModal {
  width: 220px;
  margin-top: 10px;
}

.ptContainer {
  width: 220px;
  margin-top: 10px;
}
