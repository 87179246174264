@import '../../../colors.scss';

.statusContainer {
  position: absolute;
  flex-direction: column;
  display: flex;
  padding: 5px 5px 5px 10px;
  background-color: $normalColor;
  border: 1px solid $secondaryBorder;
  z-index: 2;
  margin-left: 5%;
  margin-top: -100px;
}

.statusItemOK {
  flex-direction: row;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.statusItemNOK {
  flex-direction: row;
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: $secondaryText;
}
