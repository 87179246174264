@import '../../../colors.scss';

.btn {
  border-radius: 5px;
  background-color: $secondaryColor;
  color: #fff;
  font-weight: bold;
  width: 150px;
  margin: 15px;

  &.btnFinish {
    background-color: #95c511;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.steps {
  list-style: none;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1;
  border-radius: 3px;
  display: flex;
  //justify-content: center;
  flex-flow: row wrap;
}

.steps>li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  min-height: 60px;
  max-height: 60px;
  height: 60px;
  background-color: #d9d9d9;
  margin: 5px;
  cursor: pointer;
}

.steps li>i:after {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  position: absolute;
  left: 0;
  border: solid transparent;
  top: 0px;
  z-index: 1;
  border-left-color: $primaryBorder;
  border-width: 30px;
}

.steps li>i:before {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  position: absolute;
  top: 0;
  right: 0;
  border: solid transparent;
  border-right-color: #d9d9d9;
  transform: scaleX(-1);
  margin-right: -60px;
  border-width: 30px;
}

.steps li>i:before {
  z-index: 2;
}

.steps li:first-child>i:after {
  display: none;
}

.steps li>span {
  font-weight: bold;
  font-size: 1em;
  color: #000;
  margin-right: -40px;
  max-width: 120px;
}

.steps>li.done {
  background-color: #95c511;
}

.steps>li.done span {
  color: #ffffff;
}

.steps li.done>i:before {
  border-right-color: #95c511;
}

.steps>li.active {
  background-color: $secondaryColor;
}

.steps>li.active span {
  color: #ffffff;
}

.steps li.active>i:before {
  border-right-color: $secondaryColor;
}

.listSamples {
  max-width: 1366px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  align-self: center;
  margin: auto;
  text-align: center;
}

.listSamples>.samples {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px 5px 3px rgba(219, 219, 219, 1);
  border-radius: 5px;
  margin: 10px 0px;
}

.needAddComment {
  border: 1px solid #da1600;
}

.listSamples>div:first-child,
.listSamples>.samples>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listSamples>div:first-child>div:last-child {
  display: flex;
  justify-content: flex-end;
}

.activeOk {
  background-color: #95c511 !important;
}

.activeNok {
  background-color: #da1600 !important;
}

.Modal {
  margin: 80px auto !important;
}

.cardRowModal {
  flex-flow: row wrap !important;
  justify-content: space-evenly;
  justify-items: center;
  text-align: center;
  margin-bottom: 3em;
  margin-top: 5em;
}

.h1Title {
  align-self: center;
  font-size: 35px !important;
  justify-content: center;
  text-align: center;
}

.h3Title {
  align-self: center;
  font-size: 20px !important;
  justify-content: center;
  text-align: center;
}

.buttonsWrapper {
  flex-direction: row;
  align-self: center;
  padding: 10px;
}

.table {
  margin: 15px;
}

.table tr td,
.table tr th {
  padding: 10px;
}

.sampleColumn {
  min-width: 12%;
}

.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.back {
  border-radius: 40px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 40px;
  height: 40px;
  padding: 4px 0px 0px 0px;
}

.checke {
  position: relative;
  -webkit-appearance: none;
  width: 52px;
  height: 26px;
  line-height: 26px;
  background: #dddddd;
  border-radius: 26px;
  outline: none;
}

.checke:hover {
  cursor: pointer;
}

.checke:before {
  position: absolute;
  top: 2px;
  left: 2px;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 5px #ddd;
  transition: all 0.2s linear;
}

.checke:checked {
  background: #fa930d;
}

.checke:checked:before {
  left: 28px;
  transition: all 0.2s linear;
}

.okForAll {
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  width: 166px;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}