@import '../../colors.scss';

.userImage {
	border: 1px solid $secondaryBorder;
	padding: 0px;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;
}

.userRemove > svg {
	margin-left: -25px;
    float: right;
    position: relative;
    color: $secondaryText;
}