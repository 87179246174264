@import '../../colors.scss';

.cardImage {
	border: 1px solid $secondaryBorder;
	padding: 2px;
	border-radius: 4px;
	cursor: pointer;
}

.cardRemove > svg {
	margin-left: -25px;
    float: right;
    position: relative;
    color: $secondaryText;
}