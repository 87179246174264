@import './../../colors.scss';

.round {
  padding: 0;
  margin: 0;
  margin-left: 10px;
  display: flex;
  .previous {
    margin-right: 5px !important;
  }
  .next,
  .previous {
    width: 140px;
    background: #fff;
    font-size: 10px;
    height: 32px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 10px 5px;
    gap: 10px;
    border: 2px solid rgb(244, 155, 0);
    color: rgb(244, 155, 0);
    font-weight: bold;
  }

  .next {
    background-color: rgb(244, 155, 0);
    color: #fff;
  }
}
.NotButton {
  border: 0 !important;
  background: #ccc !important;
  color: #fff !important;
}

.loading {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.Confirmation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;

  .Confirm {
    background: #fff;
    width: 24vw;
    height: 30vh !important;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    word-wrap: break-word !important;
    padding: 5px !important;
    .roundlayout {
      display: flex;
      justify-content: space-between;
      width: 336px;
      .roundTitle {
        margin: 25px auto 15px;
        text-align: center;
        width: 320px;
        font-size: 20px;
        color: #000;
        font-weight: bold;
      }
    }
  }
}

.cuo1 {
  position: absolute;
  right: 0;
  top: 0;
  width: 26px;
  height: 26px;
  font-size: 26px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(244, 155, 0);
  color: #fff;
  margin: 100px;
  cursor: pointer;
}

.alertContainer {
  display: flex;
  margin: 10px;
  .concel {
    background: #fff !important;
    color: rgb(244, 155, 0) !important;
    border-radius: 0px !important;
    width: 90px !important;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(244, 155, 0) !important;
    font-size: 14px !important;
    margin: 15px 5px !important;
  }
  .confirm {
    color: #fff !important;
    border-radius: 0px !important;
    width: 90px !important;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px !important;
    background-color: rgb(244, 155, 0);
    font-size: 14px !important;
    margin: 15px 5px !important;
    cursor: pointer;
  }
}
.roundInfo {
  color: #333;
  margin: 2px 0;
  font-size: 14px;
  color: #444;
}
.type {
  color: #333;
  margin: 2px 0;
  font-size: 14px;
  color: #444;
  font-weight: bold;
}
