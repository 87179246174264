@import '../../colors.scss';

.button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  border: 0;
  padding: 10px 5px;
  border-radius: 5px;
  outline: none;
  // min-width: 120px;
  cursor: pointer;
  font-size: 1em;
  margin: 5px;
  color: $primaryText;
  flex: 1;
  background-color: $normalText;
  position: relative;
}

.button.active {
  background-color: $thirdText !important;
  color: $primaryText !important;
  border-color: $thirdText !important;
}


.button.active.buttonArrow::after {
  content: " ";
  border-width: 5px;
  border-style: solid;
  border-color: $thirdText transparent transparent transparent;
  position: absolute;
  left: calc(50% - 2.5px);
  bottom: -10px;
}