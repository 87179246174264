@import '../../../colors.scss';

.main {
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.date {
  text-align: center;
  font-size: 22px;
  color: #f5ab39;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 100px;
}
.codeText {
  margin-top: 60px;
  margin-left: 15px;
  font-size: 18px;
  font-weight: 600;
}
.change {
  width: 85%;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  margin-top: 10px;
  margin-left: 15px;
  padding-left: 5px;
}
.button {
  margin-top: 100px;
  text-align: center;
}

.off {
  border: 0;
  width: 30px;
  height: 30px;
  position: absolute;
  font-size: 30px;
  font-weight: 30px;
  right: 15px;
  top: 15px;
  background: rgba($color: #000000, $alpha: 0);
}
.off:hover {
  cursor: pointer;
}
.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;
  h3 {
    color: $secondaryColor;
  }
}
.back {
  border-radius: 40px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 40px;
  height: 40px;
  padding: 4px 0px 0px 0px;
}
