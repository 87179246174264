@import '../../../colors.scss';

.Modal {
  width: 420px;
  display: absolute;
  top: 20%;
  .modalContainer {
    padding: 20px;
    .topContainer {
      display: flex;
      justify-content: space-between;
    }
    .tasterContainer {
      display: flex;
      align-items: center;
      width: 96%;
      .currentTasterText {
        width: 80px;
      }
      .tasterName {
        margin-left: 6px;
        font-weight: bold;
        font-size: 16px;
        word-wrap: break-word;
        width: calc(96% - 74px);
      }
    }
  }
  .labelName {
    margin-top: 15px;
  }
  .input {
    position: relative;
    .alcohol {
      height: 32px;
    }
    .edit {
      color: #f49b00;
      position: absolute;
      right: 16px;
      top: 16px;
      font-size: 5px;
      border: none;
    }

    input:disabled {
      background: #ededed;
      cursor: not-allowed;
    }
    input {
      font-weight: bold;
    }
  }

  .del {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: 300;
    position: absolute;
    z-index: 1;
    top: 15px;
    right: 10px;
    cursor: pointer;
  }
}
.buttonContainer {
  margin: 25px auto 0;
  display: flex;
  justify-content: center;
  .cancelButton,
  .confirmButton {
    background-color: #f49b00;
    border-radius: 5px;
    font-weight: 500;
    width: 100px;
    height: 40px;
    color: white;
    font-size: 14px;
  }
  .cancelButton {
    background-color: white;
    border: 2px solid #f49b00;
    color: #f49b00;
  }
}

.ModalSuccess {
  width: 340px;
  // height: 210px;
  display: absolute;
  top: 20%;
  padding: 20px;
  .right {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
  .alert {
    color: #d1a333;
    font-size: 40px;
  }
  .wrong {
    color: #0ba248;
    font-size: 40px;
  }

  .modalSucContainer {
    margin: 0 auto;
  }

  .attention {
    font-size: 26px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin: 10px 0 0;
  }

  .reminder {
    margin: 10px 0;
    font-size: 16px;
    height: 46px;
    line-height: 23px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .del {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-weight: 300;
    position: absolute;
    z-index: 1;
    top: -15px;
    right: -10px;
    cursor: pointer;
  }
}

.commonSite {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkBreathalyzer {
  display: flex;
  align-items: center;
  .status {
    margin-right: 20px;
    .linkBreathalyzerRt {
      color: $errorBackgroundColor;
    }
    .linkBreathalyzerGt {
      color: $successBackgroundColor;
    }
  }
}
.linkBreathalyzerBtn {
  width: auto;
  padding: 10px;
}
