@import '../../colors.scss';

// .cardStyle{
// 	background-color: '#F49C00';
// 	border-radius: 5px;
// 	flex: 1;
// 	align-self: 'stretch';
// 	padding: 5px;
// 	margin-top: 10px;
// 	margin-left: 10px;
// 	width: 150px;
// 	height: 205px;
// }

// .imageWrapper {
// 	width: 100%;
// 	height: 130;
// 	background-color: '#FFF';
// 	border-radius: 5;
// 	margin: auto;
// 	display: flex;
// 	align-self: center;
// 	justify-content: center;
// 	padding: 5px;
// }

 
.textView {
	flex-direction: row;
	align-self: center;	
    padding: 5px;
    color: "#fff";
    text-align: center;    
}

// .text {
// 	color: 'white';
// 	font-weight: bold;
// 	font-size: 12;
// }

// .textNumber {
// 	margin-top: -1;
// 	color: 'white';
// 	font-size: 14px;
// 	font-weight: 'bold'
// }

.cursorPointer{
    cursor: pointer;
}

.cursorPointer img{
    cursor: pointer;
}

.cursorPointer img{
    cursor: pointer;
}

.cursorPointer label{
    cursor: pointer;
}