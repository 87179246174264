@import '../../colors.scss';

.modalHide {
  display: none;
}

.modal {
  display: block;
  position: absolute;
  top: 0;
  z-index: 10;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.15);
  overflow: auto;
}
.modalContainer {
  position: relative;
  width: 75vw;
  display: flex;
  background-color: #fff;
  margin: 10px auto;
  border-radius: 4px;
}

.modalBody {
  position: relative;
  width: 100%;
}
.modalHeader {
  width: 100%;
  height: 45px;
  color: #000;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
}