@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.attention {
  width: 600px;
  position: fixed;
  left: calc(100vw - 300px - (100vw - 180px) / 2);
  top: 30%;
  padding: 20px;

  .isKPIBrandNotChangeContent {
    min-height: 120px;

    .isKPIBrandNotChangeItem {
      margin: 5px;
    }
  }

  .buttonContainer {
    margin-top: 20px;
    // margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }
}

.confirmButton {
  border-radius: 5px;
  color: white;
  font-size: 14px;
}

.cancelButton {
  background-color: white;
  border: 2px solid #f49b00;
  border-radius: 5px;
  color: #f49b00;
}