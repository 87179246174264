@import "../../colors.scss";

.mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.2);
    z-index: 15;
    .item{
        width: 480px;
        height: 855px;
        padding: 12px 16px;
        position: absolute;
        right: 15px;
        top: 15px;
        background: #fff;
    }
}