@import '../../colors.scss';

.table {
  background: $normalColor;
  overflow: hidden;
  border-spacing: 0px;
  width: 100%;
  margin: 8px auto;
  position: relative;
  overflow-x: scroll;
  min-height: 300px;

  * {
    position: relative;
  }

  thead tr th {
    height: 40px;
    font-weight: 500;
    color: $normalText;
  }

  thead tr .description {
    background: $normalColor;
  }

  tbody tr {
    height: 35px;
  }

  td,
  th {
    text-align: left;
  }

  td.l,
  th.l {
    text-align: right;
  }

  td.c,
  th.c {
    text-align: center;
  }

  td.r,
  th.r {
    text-align: center;
  }

  /* BORDERS  */
  td,
  th {
    padding: 2px 8px;
    font-size: 0.9em;
    border-right: solid 1px $secondaryBorder;
    border-bottom: solid 1px $secondaryBorder;
  }

  tr:first-child th {
    border-top: solid 1px $secondaryBorder;
  }

  tr th:first-child,
  tr td:first-child {
    border-left: solid 1px $secondaryBorder;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 4px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 4px;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 4px;
    width: auto;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 4px;
  }
}

.tbody {

  tr:nth-child(even) {
    background-color: $normalColor;
  }

  tr:hover {
    background-color: $normalColor;
  }
}

.tbodyNonData {
  tr {
    td {
      text-align: center;
      border: 1px solid $secondaryBorder;
      border-top: none;
    }
  }
}

.searchFieldContainer {
  border: 1px solid $secondaryBorder;
  border-radius: 4px;
  display: flex;
  flex: 1;
  padding: 5px;
}

.searchField {
  border: none;
  outline: none;
  padding-left: 3px;
  width: calc(100% - 25px);
}

.searchIcon {
  color: $secondaryText;
}

.removeCell {
  color: $primaryColor;
  cursor: pointer;
}

.editCell {
  color: $thirdText;
  cursor: pointer;
}

.copyCell {
  color: #000;
  cursor: pointer;
}

.inputCell {
  color: #f49b00;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.editCellBlock {
  color: '#DADADA';
  opacity: 0.2;
  cursor: not-allowed;
}

.actionCell {
  text-decoration: underline;
  cursor: pointer;
  color: $secondaryText;
}

.img {
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.column_options {
  width: 150px !important;
  height: 34px;
  background: #ff5c00;
  border-radius: 4px !important;
  color: #fff;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  display: flex;
  float: right;
}

.checke {
  display: block;
  margin: 0 auto;
  position: relative;
  -webkit-appearance: none;
  width: 52px;
  height: 26px;
  line-height: 26px;
  background: #dddddd;
  border-radius: 26px;
  outline: none;
}

.checke:hover {
  cursor: pointer;
}

.checke:before {
  position: absolute;
  top: 2px;
  left: 2px;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 5px #ddd;
  transition: all 0.2s linear;
}

.checke:checked {
  background: #fa930d;
}

.checke:checked:before {
  left: 28px;
  transition: all 0.2s linear;
}

.checkConfirm {
  display: block;
  margin: 0 auto;
  position: relative;
  -webkit-appearance: none;
  width: 52px;
  height: 26px;
  line-height: 26px;
  background: #D82D2C;
  border-radius: 26px;
  outline: none;
}

.checkConfirm:hover {
  cursor: pointer;
}

.checkConfirm:before {
  position: absolute;
  top: 2px;
  left: 2px;
  content: '';
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 5px #ddd;
  transition: all 0.2s linear;
}

.checkConfirm:checked {
  background: #14A011;
}

.checkConfirm:checked:before {
  left: 28px;
  transition: all 0.2s linear;
}


.alcoholBold {
  font-weight: bold;
}

.alcoholPk {
  background: #ffdcde;
}

.alcoholGr {
  background: #e3f4ee;
}

.alcoholPkB {
  background: #ffdcde;
  font-weight: bold;
}

.alcoholGrB {
  background: #e3f4ee;
  font-weight: bold;
}

.dateBox {
  margin-top: -8px;
}

.dateItem {
  height: 20px;
}

.trH50 {
  height: 50px;
}

.tableOverflowX {
  width: 100%;
  overflow-x: auto;
}

.tableOverflowXMinHeight {
  width: 100%;
  overflow-x: auto;
  min-height: 250px;
}

.strRemark {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.strRemarkTooltip {
  max-width: 250px;
  white-space: normal;
  word-break: break-all;
}

.addBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.applicationForm {
  text-align: center;
}

.applicationFormResult {
  text-align: center;
}

.view {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.upload {
  height: 36px;
  width: 36px;
  cursor: pointer;
}

.viewResult {
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin-right: 4px;
}

.deleteIcon {
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin-left: 4px;
}

.taste {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.rangeRangePickerRow {
  display: flex;
  align-items: center;
}

.register {
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus,
.minus {
  padding: 4px;
  margin: 4px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.allButton {
  cursor: pointer;
}

.column_optionSample {
  margin-left: 10px;
}

.keyIcon {
  margin: 0 auto;
  background-color: $secondaryColor;
  color: $normalColor;
  padding: 5px;
  border-radius: 16px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.emailResponse {
  margin: 0 auto;
  width: 32px;
  display: block;
}

.helpCircle {
  color: $secondaryText;
  margin-left: 5px;
  cursor: pointer;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
}

.closeButton {
  position: relative;
  width: 100%;
  padding: 10px 15px;
  justify-content: flex-end;
  display: flex;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    height: 35px;
    width: 35px;
    color: $normalText;
    border-radius: 4px;
    font-size: 18px;
    border: none;
    position: relative !important;
    right: -6px;
    top: -6px;
  }
}

.QRCode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 460px !important;
}