@import '../../colors.scss';

$tabBorder: 1px solid $secondaryBorder;

.tabContainer {
  display: flex;
  flex-direction: row;
  align-items: end;
  flex-wrap: nowrap;
  margin-top: 30px;
  width: 100%;
  overflow-y: auto;
  border-radius: 6px 6px 0 0;
  margin-bottom: 1px;
}

.tabContainer::-webkit-scrollbar {
  height: 8px;
}

.tabContainer::-webkit-scrollbar-track {
  // background-color: ;
}

.tabContainer:hover::-webkit-scrollbar-thumb,
.tabContainer::-webkit-scrollbar-thumb {
  // background: #f49b00;
  border-radius: 8px;
}

.tab {
  background-color: $normalColor;
  border-left: $tabBorder;
  border-top: $tabBorder;
  border-right: $tabBorder;
  border-bottom: $tabBorder;
  border-radius: 6px 6px 0 0;
  font-weight: 500;
  color: $thirdText;
  padding: 6px 12px;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
  margin-right: 1px;
}


.close svg {
  float: right;
  width: 14px;
  height: 14px;
}

.tabContent {
  background-color: $normalColor;
  border: $tabBorder;
  margin-top: -1px;
  // min-height: calc(100% - 170px);
  // max-height: 100%;
  padding: 12px;
}

.selected {
  border: 1px solid $secondaryColor;
  border-bottom: 2px solid $secondaryColor;
}

.disabled {
  border: 1px solid #c7c7c7;
  border-bottom: 2px solid #d0d0d0;
  background-color: #ececec;
  color: #ababab;
  cursor: not-allowed;
}

.complete {
  color: $primaryText;
  background-color: $secondaryColor;
  border-bottom: 1px solid $secondaryColor;
}

.tabButton,
.tabDisabledBtn {
  color: black;
  border: 1px solid #dadada;
  border-radius: 4px 4px 0 0;
  font-weight: 500;
  padding: 10.5px 31px;
  width: 220px;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
  height: 42px;
  border-bottom: 2px solid #dadada;
}

.tabDisabledBtn {
  height: 42px;
  cursor: not-allowed;
  background-color: #ccc;
  color: #eee;
  border: 2px solid transparent;
}

.desc {
  width: 90%;
  overflow: hidden;
  margin-right: 5px;
}