@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.helpCircle {
  color: $secondaryText;
  margin-left: 5px;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
}

.uploadCard {
  display: flex;
  margin-top: 30px;
  margin-right: 10px;
}

.kpiSamplesCodes {
  width: 100%;
  max-width: 400px;
}

.countDownField {
  margin-left: -10px;
}

.countDownDiv {
  flex: 1;
  margin-right: 10px;
}

.creatRoundDiv{
  flex: 1;
  margin: 10px 10px 0px 0px;
}
