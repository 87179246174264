@import '../../colors.scss';

%default-label-style {
  font-size: 1em;
  display: block;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

.boldLabel {
  @extend %default-label-style;
  font-weight: 500;
}

.neutralLabel {
  @extend %default-label-style;
  color: $normalText;
}

.capitalLabel {
  text-transform: uppercase;
}

.hidenText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: keep-all;
}

.hiden2Text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.tooltip {
  white-space: normal;
  word-break: keep-all;
  max-width: 250px;
}