@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;

  .avatar {
    border-radius: 50%;
    outline: none;
    background-color: #F6F8FA;
  }

  .abiID {
    margin-top: 10px;
    font-weight: 600;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }

  .card {
    background: #fff;
    border-radius: 10px;
    padding: 24px !important;
    border: 0.2px solid #DADADA;

    .title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .roleTitle {
      font-size: 16px;
      margin-bottom: 8px;
    }

    .teamUser {
      display: flex;
      align-items: center;

      .teamUserInfo {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 210px;

        .teamUserName {
          font-weight: 500;
          font-size: 14px;
          line-height: 28px;
        }

        .teamUserName::after {
          content: "";
          display: block;
          height: 2px;
          background-color: #0f1d40;
          border-radius: 2px;
          margin-top: 0;
        }

        .teamUserEmail {
          color: #8c93a3;
          font-size: 12px;
          white-space: normal;
          overflow-wrap: break-word;
        }
      }
    }
  }

  .onlyShowText {
    background: #f4f7f9 !important;
    border-color: #f4f7f9;
  }

  .onlyWorkDayShowText {
    background: transparent !important;
    border: none;
    padding: 11px 5px 11px 0px !important;
  }

  .upnContainer {
    flex: 1;
    margin: 10px 10px 0 0;

    .upnTitle {
      font-weight: bold;
    }

    .upnText {
      word-break: break-all;
      margin: 8px 0 0;
      padding: 11px 5px 11px 0;
    }
  }
}