@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.buttonBackGround {
  width: 100%;
  height: 100%;
}

.showFull {
  width: 100%;
  height: 100%;
}