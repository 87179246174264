@import '../../colors.scss';

.pageContainer {
	flex-direction: column;
    display: flex;
    padding: 5px;
}

.Modal {
    margin: 80px auto !important;
    max-width: 500px;
}

.btnClose{    
    display:  flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
	background-color: transparent;
	height: 35px;
	width: 35px;
	color: $normalText;	
	border-radius: 4px;
	font-size: 18px;	
}

.helpCircle{
    color: $secondaryText;
    margin-left:5px ;
}


.tooltip{
    white-space: normal;
    max-width: 250px;
}

.buttonLink{
    color: rgba(0, 90, 158, 1);
}

.buttonLink:hover{
    text-decoration: underline;
}