@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.Modal {
  margin: 80px auto !important;
  max-width: 500px;
}

.btnClose {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  height: 35px;
  width: 35px;
  color: $normalText;
  border-radius: 4px;
  font-size: 18px;
}

.helpCircle {
  color: $secondaryText;
  margin-left: 5px;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
}

.buttonLink {
  color: rgba(0, 90, 158, 1);
}

.buttonLink:hover {
  text-decoration: underline;
}

.pageContainerHeader {
  margin: '0px';
  padding: '0px';
}

.Modal {
  margin: 80px auto !important;
}

.cardRowModal {
  flex-flow: row wrap !important;
  justify-content: space-evenly;
  justify-items: center;
  text-align: center;
  margin-bottom: 3em;
  margin-top: 5em;
}

.h1Title {
  align-self: center;
  font-size: 35px !important;
  justify-content: center;
  text-align: center;
}

.h3Title {
  align-self: center;
  font-size: 16px !important;
  justify-content: center;
  text-align: center;
}

.buttonsWrapper {
  flex-direction: row;
  align-self: center;
  padding: 10px;
}

.textInput {
  border-radius: 50px;
  background-color: '#efefef';
  padding: 5;
  height: 25px;
}

.helpCircle {
  color: $secondaryText;
  margin-left: 5px;
}

.tooltip {
  white-space: normal;
  max-width: 250px;
}

