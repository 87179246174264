@import "../../colors.scss";
.buttonShow {
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 12px;
}

.label {
  cursor: pointer;
}