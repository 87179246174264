@import '../../colors.scss';

.appMenu {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	height: calc(100vh - 60px);
	width: 180px;
	min-width: 180px;
	background: $normalColor 0% 0% no-repeat padding-box;
}

.appNav {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

ul {
	list-style-type: none;
	width: 100%;
}

.appNav li div  {
	display: flex;  
	flex-direction: column;          
}

.appNav li div label {
	width: 100%;
	cursor: pointer;
	white-space: nowrap;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 10px;
}


.appSubmenu {
	display: none;
}

.appNav li div {    
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.appNav li a {
	text-decoration: none;
	padding: 10px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:hover{
		background-color: $primaryColor;
		color: $primaryText;
		cursor: pointer;
	}	
}

.appNav li.active > a {
	background-color: $primaryColor;
	color: $primaryText;
}

.appNav li ul li a {
	padding: 10px 20px;
}

.appSubmenu li.active {
	background-color: $primaryColor;
	color: $primaryText;
}

.appNav li.active .appSubmenu {
	transition: display 2s;     
	display: flex !important;
	flex-direction: column;
}

.appNav li div label svg{ 
	transition: transform 1s; 
}

.appNav li.active div label > svg{   
	transform:rotate(90deg);
	-ms-transform:rotate(90deg);
	-webkit-transform:rotate(90deg);  
}