@import '../../colors.scss';

.cardDocument {
  border: 1px solid $secondaryBorder;
  padding: 2px;
  border-radius: 4px;
  cursor: pointer;
}

.cardRemove > svg {
  margin-left: -25px;
  float: right;
  position: relative;
  color: $secondaryText;
}

.fileName {
  display: flex;
  width: 100%;
  span:nth-child(1) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span:nth-child(2) {
    flex: 1;
  }
}

.tooltip {
  max-width: 250px;
  white-space: normal;
  word-break: break-all;
}
