@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.container {
  background-color: '#efefef';
  flex: 1;
  flex-direction: 'row';
  flex-wrap: 'wrap';
}

.filter {
  flex: 1;
  padding: 4;
}

.filterTitle {
  font-size: 10px;
}

.filterInput {
  margin-top: 4px;
  border-radius: 50px;
}

.filterWrapper {
  background-color: '#FFF';
  height: 55px;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-color: '#efefef';
  flex-direction: row;
  justify-content: 'space-between';
  flex-wrap: 'wrap';
}

.dateInput {
  max-width: 50px;
  border-radius: 50px;
  background-color: '#efefef';
  padding: 5px;
  height: 27px;
  flex: 1;
  border-color: '#FFF';
}

.picker {
  // inputIOS {
  //   fontSize: 12,
  //   borderRadius: 50,
  //   backgroundColor: '#efefef',
  //   borderColor: '#efefef',
  //   padding: 5,
  //   paddingLeft: 8,
  //   height: 25
  // },
  // inputAndroid: {
  //   fontSize: 12,
  //   borderRadius: 50,
  //   backgroundColor: '#efefef',
  //   borderColor: '#efefef',
  //   padding: 5,
  //   paddingLeft: 8,
  //   height: 25
  // },

  border-radius: 50px;
  background-color: '#efefef';
  border-color: '#efefef';
  padding: 5px;
  height: 25px;
}

.textInput {
  border-radius: 50px;
  background-color: '#efefef';
  padding: 5;
  height: 25px;
}

.roundPickerBorders {
  border-radius: 50px;
  overflow: 'hidden';
  height: 25px;
  margin-top: 4px;
}

.dateClear {
  position: 'absolute';
  right: 15px;
  color: '#999';
  top: 28px;
}

.iconPicker {
  margin-top: 6px;
  margin-right: 6px;
}

.samplesCategories {
  width: 183px;
  height: 197px;
  background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 55px;

  span {
    font-weight: 700;
    font-size: 14px;
  }
}

.samplesCategorieOptions {
  border-radius: 10px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.samplesCategorieOptionsHorizontal {
  border-radius: 10px;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.optionSet {
  display: flex;
  justify-content: center;
  font-size: 18px;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 5px;
  margin-right: 10px;
}

.optionSelect {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 93px;
  height: 87px;
  background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
  background: #fff1cc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.optionUnselect {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 93px;
  height: 87px;
  background: var(--unnamed-color-f1f1f1) 0% 0% no-repeat padding-box;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.5;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.optionLeft {
  width: 103px;
  height: 90px;
  margin-bottom: 6px;
  vertical-align: bottom;
  display: flex;
  text-align: center;
  justify-content: center;
  justify-items: center;
  justify-self: center;
}

.optionTop {
  width: 93px;
  height: 10px;
  margin-left: 12px;
  vertical-align: bottom;
  display: flex;
  text-align: center;
  justify-content: end;
  justify-items: center;
  justify-self: center;
}

.Modal {
  margin: 80px auto !important;
}

.cardRowModal {
  flex-flow: row wrap !important;
  justify-content: space-evenly;
  justify-items: center;
  text-align: center;
  margin-bottom: 3em;
  margin-top: 5em;
}

.h1Title {
  align-self: center;
  font-size: 35px !important;
  justify-content: center;
  text-align: center;
}

.h3Title {
  align-self: center;
  font-size: 16px !important;
  justify-content: center;
  text-align: center;
}

.buttonsWrapper {
  flex-direction: row;
  align-self: center;
  padding: 10px;
}

.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;
  h3 {
    color: $secondaryColor;
  }
  justify-content: flex-start;
}

.back {
  border-radius: 40px;
  background-color: #f49b00;
  color: #fff;
  font-weight: bold;
  width: 40px;
  height: 40px;
  padding: 4px 0px 0px 0px;
}

.circleInfo {
  align-self: center;
  margin-top: 30px;
  color: #f49b00;
}

.optionDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.optionSpan {
  margin-top: -28.5px;
  font-weight: 700;
  font-size: 16px;
  color: #000;
}

.center {
  flex: none !important;
  display: flex;
  align-items: center;
  font-size: 19px;
  color: #666666;
}

.alert {
  color: #f49b00;
  font-size: 19px;
  margin: -2px 2px;
}

.alertText {
  font-size: 16px;
  color: #000;
}

.alertDiv {
  display: flex;
  justify-content: center;
  margin-left: 200px;
  margin-top: 10px;
}
.right {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px !important;
}

.countDownDiv {
  position: absolute;
  right: 26%;
  top: 0px;
}
.countDownColumn {
  position: relative;
}

.alertRow {
  justify-content: center;
  padding-top: 10px;
}
