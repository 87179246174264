@import '../../../colors.scss';

.pageContainer {
    flex-direction: column;
    display: flex;
    padding: 5px;
}

.pageContainerHeader {
    padding: 0px 15px 20px 10px;
    margin-bottom: 10px;
    border-bottom: solid 2px $primaryBorder;
}
