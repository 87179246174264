@import '../../colors.scss';

.textFieldContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  >input {
    border: 1px solid $secondaryBorder;
    border-radius: 4px;
    color: $normalText;
    margin-top: 8px;
    padding: 11px 5px;
    outline: none;
  }
}

input[name='flt_concentration']::-webkit-outer-spin-button,
input[name='flt_concentration']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[name='flt_concentration'][type='number'] {
  -moz-appearance: textfield;
}

.textFieldContainerWithouBorder {
  display: flex;
  flex-direction: column;
  flex: 1;

  >input {
    border: none;
    color: $normalText;
    padding: 11px 5px;
    outline: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.labelTextBox {
  display: flex;
  align-items: center;
}

.isIconRequired {
  margin-left: 6px;
  width: 16px;
}
