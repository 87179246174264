@import './../../colors.scss';

:root {
  --svg: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="white" d="M0,32L21.8,74.7C43.6,117,87,203,131,202.7C174.5,203,218,117,262,96C305.5,75,349,117,393,128C436.4,139,480,117,524,96C567.3,75,611,53,655,90.7C698.2,128,742,224,785,240C829.1,256,873,192,916,144C960,96,1004,64,1047,80C1090.9,96,1135,160,1178,192C1221.8,224,1265,224,1309,202.7C1352.7,181,1396,139,1418,117.3L1440,96L1440,0L1418.2,0C1396.4,0,1353,0,1309,0C1265.5,0,1222,0,1178,0C1134.5,0,1091,0,1047,0C1003.6,0,960,0,916,0C872.7,0,829,0,785,0C741.8,0,698,0,655,0C610.9,0,567,0,524,0C480,0,436,0,393,0C349.1,0,305,0,262,0C218.2,0,175,0,131,0C87.3,0,44,0,22,0L0,0Z" /></svg>');
}

.loginContainer {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: var(--svg) no-repeat;
  background-color: $secondaryColor;
}

.loginBox {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  height: 300px;
  width: 420px;
  max-width: 100vw;
  border-radius: 10px;
  box-shadow: 0px 0px 6px $secondaryColor;
  position: relative;
  padding: 25px;
}

.loginLogo {
  display: flex;
  flex-direction: column;
  pointer-events: none;
  justify-content: space-evenly;
}

.invalid {
  margin-bottom: 15px;
  color: $primaryColor;
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
}

.templateUser {
  position: absolute;
  bottom: 2px;
}

.loginForm {
  margin-top: 25px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-items: center;

  .Title {
    color: #f49b00;
    text-align: center;
    margin-bottom: 25px;
  }
}