@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.buttonContainer {
  text-align: center;
  display: flex;
  justify-content: center;
}

.editUsers {
  padding: 10px 10px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  width: 85vw;

  .editUsersContent {
    display: flex;
    height: 100%;

    .editUsersContentUsers {
      flex: 2;
      max-width: 66%;
      margin-right: 16px;
      margin-top: -6px;
      display: block;
    }

    .editUsersContentConfirm {
      flex: 1;
      min-height: 100%;
      max-height: calc(100vh - 90px);
      overflow-y: auto;
      background: #fff;
      padding: 12px;
      border-radius: 4px 4px 0 0;

      .tipsTitle {
        display: flex;
        align-items: center;
        font-size: 12px;
        flex-wrap: wrap;

        .tags {
          display: inline-block;
          width: 14px;
          height: 10px;
          border-radius: 2px;
          background: #e7e7e8;
          margin: 0px 4px 0px 6px;
        }
      }

      .confirmUserBox {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .confirmUser {
        height: 40px;
        width: 23%;
        background: #ff9700;
        border-radius: 4px;
        margin-right: 6px;
        margin-bottom: 6px;
        padding: 4px;
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: space-between;

        .userName {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          cursor: pointer;
        }

        .upUserConfirm {
          font-weight: bold;
          margin-left: 4px;
        }
      }
    }
  }
}

.tabHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-wrap: wrap;

  .sum {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background: #999;
    // color: #fff;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 18px;
    font-weight: bold;
  }

  .time {
    text-align: left;

    p:nth-child(1) {
      font-size: 14px;
      white-space: nowrap;
    }

    p:nth-child(2) {
      font-size: 10px;
      white-space: nowrap;
    }
  }
}

.tabContent {
  .sessionInfo {
    width: 100%;
    min-height: 70px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    margin-bottom: 12px;

    .session {
      font-size: 22px;
      font-weight: bold;
      margin-right: 20px;
      width: 120px;
    }

    .infoItem {
      flex: 1;
      height: auto;
      margin: 0 10px;
      word-break: keep-all;

      b {
        font-size: 16px;
      }

      p {
        font-size: 14px;
        margin-top: 4px;
        color: #9f9f9f;
        font-weight: bold;
      }
    }
  }

  .tableContent {
    min-height: 200px;
    max-height: calc(100vh - 290px);
    overflow-y: auto;
  }

  .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.tips {
  display: flex;
  align-items: center;

  p {
    display: flex;
    align-items: center;
  }
}

.lump {
  width: 16px;
  height: 11px;
  display: inline-block;
  border: 2px solid #87c700;
  margin: 0 4px 0 20px;
  border-radius: 3px;
}

// .tips:nth-child(1) p:nth-child(1) span {
//   margin-left: 4px;
// }

.title {
  font-size: 14px;
  margin: 12px 0 6px;
  display: inline-block;
}

.userContent {
  display: flex;
  flex-wrap: wrap;

  .user {
    display: flex;
    padding: 4px;
    border-radius: 4px;
    width: 18%;
    margin: 4px 8px 4px 0;
    height: 42px;
    border: 3px solid;
    align-items: center;

    .userInfo {
      min-width: 10px;
      margin-right: 6px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;

      &:nth-child(1) {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.checkConfirm {
  display: block;
  margin: 0 auto;
  position: relative;
  -webkit-appearance: none;
  width: 38px;
  height: 20px;
  line-height: 26px;
  background: #e9e9e9;
  border-radius: 26px;
  outline: none;
}

.checkConfirm:hover {
  cursor: pointer;
}

.checkConfirm:before {
  position: absolute;
  top: 2px;
  left: 2px;
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 0px 5px #ddd;
  transition: all 0.2s linear;
}

.checkConfirm:checked {
  background: #14A011;
}

.checkConfirm:checked:before {
  left: 20px;
  transition: all 0.2s linear;
}

.tooltip {
  max-width: 250px;
  white-space: normal;
  word-break: break-all;
}