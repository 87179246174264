@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.newProfileButton{
  width: 100px;
  border-radius: 4px;
}
.newProfileRow{
  justify-content: end;
  padding: 5px;
}