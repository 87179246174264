@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.draggableCardRow {
  background-color: $normalColor;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0;
  align-items: center;
}

.sampleCard {
  flex: 3;
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid $secondaryBorder;
  background-color: $normalColor;
  border-radius: 4px;
  padding: 10px 0px 10px 10px;
  margin: 5px 0px 0px 0px;
}

.sampleInputText {
  min-width: 360px;
}


.sampleCardNumber {
  margin: 8px 20px 0px 0px;
  padding: 14px;
  border-radius: 50%;
  background-color: $primaryBorder;
  font-size: 18px;
  font-weight: 500;
  color: $thirdText;
  text-align: center;
  vertical-align: middle;
  width: 52px;
  height: 52px;
}

.removeButton {
  display: flex;
  width: 30px;
  font-size: 18px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-height: 1000px) {

  .sampleInputText {
    min-width: 310px;
  }

}