@import '../../../colors.scss';

.pageContainer {
  flex-direction: column;
  display: flex;
  padding: 5px;
}

.buttonSave {
  width: 80px;
  border-radius: 4px;
}

.draggableCardRow {
  background-color: $normalColor;
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  padding: 5px 0;
  align-items: center;
  width: 49.6%;
  flex: 1 0 auto;
  min-width: 500px;
  margin-left: 5px;
  justify-content: space-between;
}

.sampleCard {
  flex: 3;
  display: flex;
  width: 100%;
  border: 1px solid $secondaryBorder;
  background-color: $normalColor;
  border-radius: 4px;
  margin: 5px 0px 0px 0px;
  position: relative;
}

.btnRemoveSample {
  position: absolute !important;
  right: 8px;
  top: 8px;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.manipulate {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.sampleCardNumber {
  margin: 0px 5px 0px 0px;
  padding: 14px;
  border-radius: 50%;
  background-color: $primaryGrey;
  font-size: 18px;
  font-weight: 500;
  color: $thirdText;
  text-align: center;
  vertical-align: middle;
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
}

.btnCopySample {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 26px;
  height: 26px;
}