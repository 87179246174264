@import '../../colors.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
}

.template {
  display: flex;
  flex-direction: column;
}

.templateHeader {
  background-color: $normalColor;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background: $normalColor 0% 0% no-repeat padding-box;
}

.templateLogo {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .menu {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    margin-right: 10px;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    border-right: 2px solid #000;
  }
}

.tempalteLinkTraning {
  cursor: 'pointer';
}

.templateBoxUser {
  display: flex;
  align-items: center;
}

.templateUserBox {
  position: relative;
}

.templateUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  flex: 1;
  cursor: pointer;

  p {
    margin: 0 auto;
  }
}

.templateUser span {
  padding: 5px;
}

.templateUser>img {
  height: 36;
  width: 36;
  border-radius: 20px;
  outline: none;
  background-color: #F6F8FA;
}

.templateHelp {
  color: $thirdText;
}

.tempalteBody {
  display: flex;
  flex-direction: row;
}

.templateUserContent {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.templateContent {
  position: relative;
  flex: 1;
  padding: 10px;
  max-width: 100vw;
  min-width: calc(100vw - 200px);
  background: $primaryBorder 0% 0% no-repeat padding-box;
  box-shadow: inset 3px 3px 4px -4px rgba(0, 0, 0, 0.47);
  min-height: calc(100vh - 90px);
}

.templateContent.hasMenu {
  max-width: calc(100vw - 200px);
}

.showMenu {
  animation: showMenu 0.5s linear;
  -webkit-animation: showMenu 0.5s linear;
  transform: translateX(0);
  width: 180px;
}

@-webkit-keyframes showMenu {
  0% {
    transform: translateX(-180px);
    width: 0px;
  }

  100% {
    transform: translateX(0);
    width: 180px;
  }
}

@keyframes showMenu {
  0% {
    transform: translateX(-180px);
    width: 0px;
  }

  100% {
    transform: translateX(0);
    width: 180px;
  }
}

.menuNull {
  width: 0px;
}

.hideMenu {
  animation: hideMenu 0.5s linear;
  -webkit-animation: hideMenu 0.5s linear;
  transform: translateX(-180px);
  width: 0px;
}

@-webkit-keyframes hideMenu {
  0% {
    transform: translateX(0);
    width: 180px;
  }

  100% {
    transform: translateX(-180px);
    width: 0px;
  }
}

@keyframes hideMenu {
  0% {
    transform: translateX(0);
    width: 180px;
  }

  100% {
    transform: translateX(-180px);
    width: 0px;
  }
}

.tempalteLinkServiceNow {
  cursor: 'pointer';
}

.templateUserMinBox {
  display: none;
}

@media screen and (max-width: 800px) {
  .templateUserContent {
    display: none;
  }

  .templateUserMinBox {
    display: flex;
    align-items: center;
  }

  .templateUser {
    padding: 0px;
    margin-right: 10px;
  }
}