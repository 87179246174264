@import '../../../colors.scss';

.pageContainer {
	flex-direction: column;
    display: flex;
    padding: 5px;
}

.helpCircle {
    color: $secondaryText;
    margin-left:5px ;
}

.tooltip{
    white-space: normal;
    max-width: 250px;
}

.uploadCard {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.helpCircleId {
    color: $secondaryText;
    height: 17px;
}