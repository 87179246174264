@import '../../../colors.scss';

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #717171;
}

.container {
  flex-flow: column !important;
  height: 100%;
  max-height: calc(100vh - 130px);
}

.containerFixed {
  flex-flow: column !important;
}

.cardInfo {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  width: 225px;
  flex: 1 1 250px !important;
  max-width: 225px;
  flex-basis: 225px;
  min-width: 225px;
  flex-grow: 1;
  margin: 5px !important;
}

.filter {
  float: left;
  width: 100%;
  height: 28px;
  border: 0;
  outline: none;
}

.tasters {
  margin: 5px;
}

.cardFilter {
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  width: 225px;
  align-items: center;
  max-width: 225px;
  flex-basis: 225px;
  min-width: 225px;
  height: 30px;
  padding: 0;
  border: 2px solid #ccc;
}

.cardTaster {
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  width: 175px;
  min-height: 250px;
  flex: 1 1 180px !important;
  max-width: 175px;
  min-width: 175px;
  flex-basis: 175px;
  flex-grow: 1;
  margin: 5px !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cardNameTaster {
  position: relative;
  overflow: hidden;
  background-color: #292929;
  color: #fff;
  border-radius: 6px;
  flex: 1 1 180px !important;
  flex-basis: 175px;
  flex-grow: 1;
  height: 30px;
  width: 175px;
  align-items: center;
  display: flex;
}

.cardNameTasterText {
  width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cardNameTasterTextW100 {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tooltip {
  white-space: normal;
  word-break: keep-all;
  max-width: 250px;
  margin-top: -20px !important;
}

.cardNameTasterParent {
  position: relative;
  overflow: hidden;
  background-color: #292929;
  color: #fff;
  border-radius: 6px;
  width: 175px;
  flex: 1 1 180px !important;
  max-width: 175px;
  min-width: 175px;
  flex-basis: 175px;
  flex-grow: 1;
  //height: 30px;
  margin: 5px !important;
  padding: 5px !important;
  align-items: center;
}

.textTitle {
  color: $secondaryColor;
}

.textSubTitle {
  color: #000;
  word-break: break-all;
}

.textSubTitleT {
  color: #000;
}

.textSubTitleVarieties {
  color: #000;
}

.header {
  padding: 0 5px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.title {
  padding: 0 10px !important;
  align-items: center;
  display: flex;

  h3 {
    color: $secondaryColor;
  }
}

.iconBack {
  width: 18px;
}

.back {
  background-color: $secondaryColor;
  color: #fff;
  float: left;
  border-radius: 16px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 5px;
}

.textScore {
  font-weight: bold;
  text-align: center;
}

.cardSeparator {
  flex-flow: column !important;
  margin-bottom: 5px;
  z-index: 1;
}

.keyIcon {
  background-color: $secondaryColor;
  padding: 5px;
  border-radius: 16px;
  position: absolute;
  right: 10px;
}

.contentCard {
  display: flex;
}

.penIcon {
  background-color: #fff;
  padding: 3px;
  border-radius: 16px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
}

.textComments {
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
  word-wrap: break-word;
}

.rank {
  color: $normalText;
  border: 1px solid $secondaryBorder;
  cursor: pointer;
  margin-left: 10px;
  width: 50px;
}

.click {
  min-height: calc(100% - 39px);
}

.simpleAlign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.dataContainer {
  width: 100%;
}

.firstCollumn {
  width: 225px;

  margin-right: 12px;
  padding-top: 65px;
}

.listMainContainer {
  width: calc(100vw - 200px);
}

.itemListing {
  overflow-y: scroll;
  overflow-x: scroll;
  height: fit-content;
  max-height: 75vh;
}

.listTable {
  min-width: max-content;
  border-collapse: separate;
  border-spacing: 0px;

  th {
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 3;
    }

    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: #f7f7f7;
    text-align: start;
    outline: 0.7px solid transparent;
    border: 0.5px solid transparent;
  }

  td {
    text-align: left;
    background-color: #f7f7f7;
    border: 0.5px solid transparent;
    position: relative;

    &:first-child {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }
}

.listDiscussion {
  width: 100%;
  height: 100%;
}

.buttonBackGround {
  width: 100%;
  height: 100%;
}

.roundName {
  font-size: 24px;
  font-weight: 600;
  color: #323232;
  margin: 20px;
}

.confirmButton {
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  width: 150px;
  margin-right: 5px;
}

.detailImg {
  width: 86px;
}