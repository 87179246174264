.backPosition {
  width: 238px;
  max-height: 60vh;
  position: fixed;
  z-index: 10;
  top: 40%;
  transform: translateY(calc(-50%));
  padding: 30px 0 0 0;
}
.oneKey {
  position: fixed;
  top: 0px;
  z-index: 1;
  width: 60px;
  right: 50%;
  transform: translateX(calc(50%));
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .cleanBtn {
    background: #fff;
    border: 2px solid #f49b00;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    width: 60px;
    height: 26px;
    border-radius: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 18px;
    }
  }
}
.ItemBarBox {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(60vh - 80px);
  .ItemBar {
    position: relative;
    min-height: 45px;
    left: 5px;
    width: 220px;
    background: rgba($color: #fff, $alpha: 0.9);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    border-radius: 35px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    animation: moveFromRight ease-in 0.3s;
    -webkit-animation: moveFromRight ease-in 0.3s;
    margin: 8px 0;
    .title {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      .beerImg {
        height: 28px;
        margin-right: 10px;
      }
      .name {
        font-weight: bold;
        font-size: 12px;
      }
      .text {
        padding-right: 30px;
      }
    }

    .closeBtn {
      position: absolute;
      width: 35px;
      height: 45px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 0;
      cursor: pointer;
      .x {
        width: 20px;
      }
    }
    .closeBtn:hover {
      color: #f49b00;
    }
  }
}
.ItemBarBox::-webkit-scrollbar,
.ItemBarBox::-webkit-scrollbar-thumb {
  background-color: #f49b00;
  width: 4px;
  border-radius: 10px;
}

@-webkit-keyframes moveFromRight {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}
@keyframes moveFromRight {
  0% {
    right: -100%;
  }
  100% {
    right: 0;
  }
}

